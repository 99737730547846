import { TransformNode } from '@babylonjs/core';
import { autorun } from 'mobx';

import { LessonStore } from 'features/lesson-page/store';

import { findInStore } from '../../common/utils';

import { InfoPanelConfig } from './types';
import { InfoPanelStore } from './store';

export function setupLogic(
  store: LessonStore,
  objStore: InfoPanelStore,
  cfg: InfoPanelConfig
): void {
  objStore.setVisibility(true);

  const parent = findInStore(store, cfg.parent);
  if (!(parent instanceof TransformNode))
    throw Error(
      `Selected parent (${cfg.parent}) must be of type TransformNode,` +
        ` but it has type ${typeof parent}`
    );
  objStore.moveTo(parent);

  autorun(() => {
    objStore.setContent(store.script.currentStep?.shortHint || '');
  });
}
