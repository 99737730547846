import {
  AbstractMesh,
  AnimationGroup,
  ISceneLoaderAsyncResult,
  Scene,
  TransformNode,
} from '@babylonjs/core';

import { findByName, loadMesh } from '../../../common/utils';

import { HandState, SingleHandConfig } from '../types';

export interface ISingleHandModel {
  /**
   * Коробка для проверки пересечений руки с объектами
   */
  get bbox(): AbstractMesh;

  /**
   * TransformNode, за которую можно крепить руку
   */
  get helperTake(): TransformNode;
}

export class SingleHandModel {
  public static readonly MODEL_VERSION = 'v0005';

  private _root: TransformNode;
  private _bbox: AbstractMesh;
  private _handMesh: AbstractMesh;
  private _gloveMesh: AbstractMesh;
  private _helperTake: TransformNode;
  private _animations: Record<HandState, AnimationGroup>;

  public get animations(): Record<HandState, AnimationGroup> {
    return this._animations;
  }

  constructor(scene: Scene, model: ISceneLoaderAsyncResult) {
    [this._root] = model.meshes;
    this._handMesh = findByName(model.meshes, 'Hand');
    this._gloveMesh = findByName(model.meshes, 'Glove');
    this._bbox = findByName(model.meshes, 'BoundingBox_Hand');
    this._helperTake = findByName(model.transformNodes, 'Helper_Take');

    this._handMesh.isPickable = false;
    this._gloveMesh.isPickable = false;
    this._bbox.isPickable = false;

    const findAnim = (name: string) => findByName(model.animationGroups, name);

    this._animations = {
      [HandState.BRUSH]: findAnim('Brush'),
      [HandState.CROCODILE]: findAnim('Crocodile'),
      [HandState.FIXED]: findAnim('Fixed'),
      [HandState.IPP]: findAnim('IPP'),
      [HandState.KETTLE_TAKE]: findAnim('KettleTake'),
      [HandState.KETTLE_FILTER_TAKE]: findAnim('FilterTake'),
      [HandState.KEY]: findAnim('Key'),
      [HandState.STAND_TAKE]: findAnim('StandTake'),
      [HandState.TABLET_HORIZONTAL]: findAnim('TabletHorizontal'),
      [HandState.TAKE]: findAnim('Take'),
      [HandState.TOUCHING]: findAnim('Touching'),
      [HandState.TUBE]: findAnim('Tube'),
      [HandState.WRENCH]: findAnim('Wrench'),
    };

    this.setVisibility(true, false);
  }

  static async load(
    cfg: SingleHandConfig,
    scene: Scene
  ): Promise<SingleHandModel> {
    const res = await loadMesh(scene, cfg.model, this.MODEL_VERSION);
    return new SingleHandModel(scene, res);
  }

  /**
   * Управлять видимостью объекта
   */
  public setVisibility(isVisible: boolean, gloveVisible: boolean): void {
    this._handMesh.isVisible = isVisible;
    this._gloveMesh.isVisible = isVisible && gloveVisible;
    this._bbox.isVisible = false;
  }

  public get root(): TransformNode {
    return this._root;
  }

  public get bbox(): AbstractMesh {
    return this._bbox;
  }

  public get helperTake(): TransformNode {
    return this._helperTake;
  }
}
