import {
  AbstractActionManager,
  ActionManager,
  ExecuteCodeAction,
  IAction,
  Observable,
  Scene,
} from '@babylonjs/core';
import { autorun } from 'mobx';

import { BaseController } from '../base';
import { IntersectionStore } from './store';
import { IntersectionTarget } from './types';

export class IntersectionController extends BaseController<
  IntersectionStore,
  undefined
> {
  private _actions: [AbstractActionManager, IAction[]][] = [];

  public onIntersection = new Observable<[number, number, boolean]>();

  constructor(scene: Scene, store: IntersectionStore) {
    super(scene, store, undefined);
  }

  protected _connectToStore(store: IntersectionStore, cfg: undefined): void {
    autorun(() => {
      store.resetMarks();
      this.clearIntersectionHook();
      store.isEnabled && this.setupIntersectionHooks(store);
    });
    this.onIntersection.add(([id1, id2, on]) => {
      store.markTarget([id1, id2], on);
    });
  }

  private clearIntersectionHook(): void {
    this._actions.forEach(([manager, as]) => {
      as.forEach((a) => manager.unregisterAction(a));
    });
    this._actions = [];
  }

  private setupIntersectionHook(
    t1: IntersectionTarget,
    targets2: IntersectionTarget[]
  ): void {
    const manager = t1.mesh.actionManager || new ActionManager(this.scene);
    t1.mesh.actionManager = manager;

    const actions: IAction[] = [];
    targets2.forEach((t2) => {
      const actionParams = {
        mesh: t2.mesh,
        usePreciseIntersection: true,
      };
      const enterAction = manager.registerAction(
        new ExecuteCodeAction(
          {
            trigger: ActionManager.OnIntersectionEnterTrigger,
            parameter: actionParams,
          },
          () => {
            this.onIntersection.notifyObservers([t1.id, t2.id, true]);
          }
        )
      );
      const exitAction = manager.registerAction(
        new ExecuteCodeAction(
          {
            trigger: ActionManager.OnIntersectionExitTrigger,
            parameter: actionParams,
          },
          () => {
            this.onIntersection.notifyObservers([t1.id, t2.id, false]);
          }
        )
      );

      enterAction && actions.push(enterAction);
      exitAction && actions.push(exitAction);
    });

    this._actions.push([manager, actions]);
  }

  private setupIntersectionHooks(store: IntersectionStore): void {
    store.targets1.forEach((t1) => {
      this.setupIntersectionHook(t1, store.targets2);
      store.targets2.forEach((t2) => {
        if (t1.mesh.intersectsMesh(t2.mesh, true))
          store.markTarget([t1.id, t2.id], true);
      });
    });
  }
}
