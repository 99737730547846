/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { LessonSession, LessonType } from 'api';
import { action, autorun, makeAutoObservable } from 'mobx';

import '@babylonjs/inspector';

import { RequiredState } from 'api/impl/web-vr-training';
import { ResultPanelStepsPageContentLineType } from '../scene-components/objects/scene/result-panel';

import { LessonExistenceType, SceneLoadingProgressType } from '../types';

import { fetchLearnerReport, isRequiredStatesFulfilled } from './utils';

import { LessonAudioHints } from '../scene-components/audio';

import { IObjectsStore, ObjectsStore } from '../scene-components/objects';
import { LessonScene, LessonSceneBuilder } from '../scene-components/init';
import { StreamInitiator } from '../scene-components/stream/stream';

import { FullscreenPanelStore } from './fullscreen-panel';

import { ScriptStore } from './script';

import { findInStore } from '../scene-components/objects/common/utils';
import { mockScript } from '../../../api/script-mock-2';

export class LessonStore {
  socket: WebSocket | null = null;

  isInitialModalVisible = true;

  doesLessonExist: LessonExistenceType = LessonExistenceType.Unknown;

  lessonType: LessonType | null = null;

  lessonId = '';
  sessionId: string | null = null;
  lessonStartTime = '';
  lessonFinishTime = '';

  isLessonTaken: boolean | null = null;
  isExamResultsPanelVisible = false;

  canvasElement: null | HTMLCanvasElement = null;

  audioHints: LessonAudioHints | null = null;

  unfulfilledRequiredStates: RequiredState[] = [];

  // TODO: Используется исключительно для работы сценария. Вероятно в будущем нужно будет переделать
  sceneState: Record<string, unknown>;

  sceneLoadingScreenProgress: SceneLoadingProgressType;

  broadcaster?: StreamInitiator;

  // TODO: Это не часть стора и во время рефакторинг необходимо вынести
  lessonScene: LessonScene | null = null;

  // *********** Отрефакторенная часть стора *********** //
  private _isVR = false;

  private _fullscreenPanel = new FullscreenPanelStore();
  private _script = new ScriptStore();
  private _objects?: ObjectsStore;

  public get isVR(): boolean {
    return this._isVR;
  }

  public get fullscreenPanel(): FullscreenPanelStore {
    return this._fullscreenPanel;
  }

  public get objects(): IObjectsStore {
    return this._objects!;
  }

  public get script(): ScriptStore {
    return this._script;
  }

  public setIsVR(isVR: boolean): void {
    this._isVR = isVR;
  }
  // *************************************************** //

  constructor() {
    makeAutoObservable(
      this,
      {
        setIsVR: action,
      },
      { autoBind: true }
    );

    this.sceneState = {
      isKettleInHand: false,
      areAllLabelsTouched: false,
      isKettleOnTable: false,
      isKettleOpen: false,
      isKettleFilterInHand: false,
      isKettleFilterInKettle: true,
      isKettleBaseInHand: false,
      isKettleBaseLabelsTouched: false,
      isKettleBaseOnTable: false,
      correctlyAnsweredTestQuestion: true,
      isKettleFilled: false,
      isTapClosed: true,
      isKettleOnBase: false,
      isCorrectPower: false,
    };

    this.sceneLoadingScreenProgress = SceneLoadingProgressType.NotLoaded;

    autorun(() => {
      this.tryToMoveToNextScriptStep();
    });

    autorun(() => {
      if (
        this.audioHints &&
        this.script.currentStep &&
        this.sceneLoadingScreenProgress === SceneLoadingProgressType.Loaded
      ) {
        this.audioHints?.playAudioHintForStep(
          this.script.currentStep.stepNumber!
        );
      }
    });

    autorun(() => {
      if (this.canvasElement && this.script.hasScript) {
        this.initScene();
      }
    });

    autorun(() => {
      this.setIsVR(this.objects?.core?.xr?.isActive || false);
    });
  }

  setSceneLoadingProgress(sceneProgress: SceneLoadingProgressType): void {
    this.sceneLoadingScreenProgress = sceneProgress;
  }

  getSceneStateByName(name: string): undefined | unknown {
    // Экспериментальное дополнение в целях улучшения архитектуры
    // Обработка состояний типа [objects.world.lands...]
    if (name.startsWith('[') && name.endsWith(']'))
      return findInStore(this, name.slice(1, -1));
    return this.sceneState[name];
  }

  // TODO: Вынести в ScriptStore
  tryToMoveToNextScriptStep(): void {
    if (!this.script.hasScript || !this.script.currentStep?.doneCondition)
      return;
    const { stateName, stateValue } = this.script.currentStep.doneCondition;

    // Если doneCondition не выполняется — просто ничего не делаем
    if (this.getSceneStateByName(stateName!) !== stateValue) return;

    console.log(
      `Шаг ${this.script.currentStepNo}. doneCondition выполнено: состояние ${stateName} изменилось на ${stateValue}`
    );

    if (
      !isRequiredStatesFulfilled(
        this.script.currentStep.requiredStates!,
        this.sceneState
      )
    ) {
      console.log('! Не все requiredStates выполнены.');
      this.handleUserMistakes();
      return;
    }
    // сначала отправить инфу о завершенном шаге на сервер, потом перейти к следующему шагу
    console.log('requiredStates выполнены.');
    this.script.gotoNextStep();
    this.unfulfilledRequiredStates = [];
    if (this.script.isScriptPassed) {
      console.log('Сценарий пройден успешно');
      this.finishLesson();
    } else {
      console.log(
        `Теперь следим за изменением состояния ${this.script.currentStep.doneCondition.stateName} (шаг ${this.script.currentStepNo})`
      );
    }
  }

  handleUserMistakes(): void {
    if (this.script.currentStep!.requiredStates) {
      switch (this.lessonType) {
        case LessonType.Learning:
          this.unfulfilledRequiredStates =
            this.script.currentStep!.requiredStates.filter(
              (state: RequiredState) => !this.sceneState[state.stateName!]
            );
          return;
        case LessonType.Training:
          // nothing, they should understand themselves
          return;
        case LessonType.Exam:
          this.finishExamWithFailure();
      }
    }
  }

  setLessonId(value: string): void {
    this.lessonId = value;
  }

  setLessonType(value: LessonType): void {
    this.lessonType = value;
    this.lessonStartTime = new Date().toLocaleString();
    this.isInitialModalVisible = false;
  }

  // TODO: Вынести в ScriptStore
  fetchLessonScript(): void {
    this.script.setScript(mockScript);
  }

  collectAllAudioHintsFromScript(): void {
    if (!this.script.hasScript) return;

    this.audioHints = new LessonAudioHints(this.script.allAudioHints);
    this.audioHints.loadAllAudioHints();
  }

  async finishLesson(): Promise<void> {
    if (this.lessonFinishTime) return;

    try {
      this.lessonFinishTime = new Date().toLocaleString();
      // не понятно, как Axios может вернуть что-то типа response.isOK
      const { status } = await LessonSession.apiLessonsessionIdFinishPost(
        this.sessionId!
      );
      if (status >= 200 && status < 300) {
        this.showUserResultsPanel();
      }
    } catch (error) {
      console.warn(error);
    }
  }

  finishExamWithFailure(): void {
    // Todo: отобразить какой-нибудь знак на экране, что произошел фейл
    console.warn('This loser has made a mistake');
    this.finishLesson();
  }

  setCanvasElement(canvasElement: HTMLCanvasElement): void {
    this.canvasElement = canvasElement;
  }

  async showUserResultsPanel(): Promise<void> {
    const resultPanel = this.objects.scene?.resultPanel;
    if (!resultPanel) return;

    const userReport = await fetchLearnerReport(this.sessionId!);
    if (!userReport) {
      console.log("Couldn't get user's report");
      return;
    }
    resultPanel.setInfoContent({
      fullName: userReport.fullName,
      lessonTitle: userReport.scriptName!,
      lessonDuration: userReport.lessonDurationFormatted,
      lessonMode: userReport.lessonTypeFormatted,
    });

    const stepsContent: ResultPanelStepsPageContentLineType[] = [];
    userReport.stepsFormatted.forEach((step) => {
      stepsContent.push({
        name: step.name || '',
        errorCount: step.errorCountFormatted,
        stepDuration: step.stepDurationFormatted,
      });
    });

    resultPanel.setStepsContent(stepsContent);
    resultPanel.setVisibility(true);
  }

  async initScene(): Promise<void> {
    if (!this.canvasElement) return;

    const sceneBuilder = new LessonSceneBuilder(this.canvasElement, true);
    this.setSceneLoadingProgress(SceneLoadingProgressType.InProgress);

    // TODO: добавить чек, есть ли вообще в скрипте аудиоподсказки
    this.collectAllAudioHintsFromScript();

    // Создание и настройка сцены
    await sceneBuilder.load(this.script.config);

    sceneBuilder.place();
    this.lessonScene = sceneBuilder.scene;
    this._objects = this.lessonScene.objects?.store;
    sceneBuilder.setupLogic(this);

    this.setSceneLoadingProgress(SceneLoadingProgressType.Loaded);
    this.lessonScene.runRenderLoop();
    // this.lessonScene.debugLayer.show();
  }
}

export const LessonStoreInstance = new LessonStore();
export type LessonStoreType = typeof LessonStoreInstance;
