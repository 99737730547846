import { Script, ScriptStep } from 'api';
import { action, makeAutoObservable } from 'mobx';

import { ObjectsConfig } from '../scene-components/objects';

export class ScriptStore {
  /**
   * Номер текущего шага сценария (начиная с 0)
   */
  private _currentStepNo = 0;
  private _script: Script | null = null;

  public get currentStepNo(): number {
    return this._currentStepNo;
  }

  constructor() {
    makeAutoObservable(this, {
      setScript: action,
      gotoNextStep: action,
    });
  }

  /**
   * Текущий шаг сценария
   */
  public get currentStep(): ScriptStep | null {
    if (!this._script?.steps) return null;
    const { steps } = this._script;
    if (this._currentStepNo >= steps.length) return null;
    return steps[this._currentStepNo];
  }

  public get currentHint(): string {
    const step = this.currentStep;
    if (!step || !step.shortHint) return '';
    return step.shortHint;
  }

  /**
   * Кол-во шагов в сценарие
   */
  public get stepsCount(): number {
    if (!this._script?.steps) return 0;
    return this._script.steps.length;
  }

  /**
   * Имеется ли активный сценарий
   */
  public get hasScript(): boolean {
    return this._script !== null;
  }

  public get config(): ObjectsConfig {
    return this._script?.config;
  }

  /**
   * Пройден ли активный сценарий
   */
  public get isScriptPassed(): boolean {
    if (this._script === null) return false;
    const { steps } = this._script;
    if (!steps) return false;

    if (this._currentStepNo >= steps.length) return true;
    // If there are no doneCondition on the step
    const done = steps[this._currentStepNo].doneCondition;
    if (done === null || done === undefined) return true;
    return false;
  }

  /**
   * Словарь всех аудиоподсказок
   */
  public get allAudioHints(): Record<string, string> {
    const stepIdToAudioFile: Record<string, string> = {};

    this._script?.steps?.forEach((step: ScriptStep) => {
      stepIdToAudioFile[step.stepNumber!] = step.audioUrl!;
    });

    return stepIdToAudioFile;
  }

  /**
   * Список всех имен шагов
   */
  public get allStepsName(): string[] {
    const steps = this._script?.steps;
    if (!steps) return [];

    const res: string[] = [];
    for (let i = 0; i < steps.length; i += 1) {
      const hint = steps[i].shortHint;
      if (hint) res.push(hint);
      else res.push(`Шаг ${i + 1}`);
    }
    return res;
  }

  /**
   * Установить скрипт
   */
  public setScript(script: Script): void {
    if (!script) return;
    this._script = script;
    this._currentStepNo = 0;
  }

  /**
   * Перейти на следующий шаг скрипта
   */
  public gotoNextStep(): void {
    if (this._currentStepNo >= this.stepsCount) return;
    this._currentStepNo += 1;
  }
}
