import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { LessonPage } from 'features/lesson-page';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

const Core: FC = observer(() => {
  return (
    <BrowserRouter basename="/">
      <Switch>
        <Route path="/">
          <LessonPage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
});

export default Core;
