import { autorun } from 'mobx';

import { LessonStore } from 'features/lesson-page/store';

import { HandType } from '../hands';
import { InventoryConfig, InventoryItemType } from './types';
import { InventoryStore } from './store';

export function setupLogic(
  store: LessonStore,
  objStore: InventoryStore,
  cfg: InventoryConfig
): void {
  autorun(() => {
    let gloveSelected: boolean | undefined;
    let mainHandItem: InventoryItemType | undefined;
    let minorHandItem: InventoryItemType | undefined;

    // Не хочет ли какой-нибудь объект взяться в руки?
    objStore.objectsWantInHand.forEach((o) => {
      const { wantInHand } = o.obj;
      if (wantInHand === undefined) return;
      if (!mainHandItem && wantInHand === HandType.MAIN) mainHandItem = o.item;
      if (!minorHandItem && wantInHand === HandType.MINOR)
        minorHandItem = o.item;
    });

    objStore.setGloveSelected(gloveSelected || false);
    objStore.mainHandItem.set(mainHandItem?.group, mainHandItem?.object);
    objStore.minorHandItem.set(minorHandItem?.group, minorHandItem?.object);
  });
}
