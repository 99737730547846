import { makeAutoObservable } from 'mobx';

import { IKettleBaseStore, KettleBaseStore } from '../kettle-base';
import { IKettleFilterStore, KettleFilterStore } from '../kettle-filter';
import { IKettleStore, KettleStore } from '../kettle';

import { KitchenwareType } from '../types';

export type KitchenwareStoreList = {
  [key in KitchenwareType]: unknown | undefined;
};

export interface IKitchenwareStore {
  /**
   * Чайник
   */
  get kettle(): IKettleStore | undefined;

  /**
   * Подставка для чайника
   */
  get kettleBase(): IKettleBaseStore | undefined;

  /**
   * Фильтр для чайника
   */
  get kettleFilter(): IKettleFilterStore | undefined;
}

export class KitchenwareStore implements IKitchenwareStore {
  private _stores: KitchenwareStoreList;

  /**
   * Чайник
   */
  public get kettle(): KettleStore | undefined {
    if (this._stores.kettle === undefined) return undefined;
    return this._stores.kettle as KettleStore;
  }

  /**
   * Подставка для чайника
   */
  public get kettleBase(): KettleBaseStore | undefined {
    if (this._stores.kettleBase === undefined) return undefined;
    return this._stores.kettleBase as KettleBaseStore;
  }

  /**
   * Фильтр для чайника
   */
  public get kettleFilter(): KettleFilterStore | undefined {
    if (this._stores.kettleFilter === undefined) return undefined;
    return this._stores.kettleFilter as KettleFilterStore;
  }

  constructor(stores: KitchenwareStoreList) {
    this._stores = stores;

    makeAutoObservable(this);
  }
}
