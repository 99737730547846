import { TransformNode } from '@babylonjs/core';
import { action, makeAutoObservable } from 'mobx';

import { AskIfAcceptWindowModel } from './model';

export interface IAskIfAcceptWindowStore {
  /**
   * Открыто ли окно
   */
  get isOpen(): boolean;

  /**
   * Какую кнопку нажал пользователь
   */
  get answer(): boolean | undefined;
}

export class AskIfAcceptWindowStore implements IAskIfAcceptWindowStore {
  private _parent?: TransformNode;
  private _model: AskIfAcceptWindowModel;

  private _isOpen = false;
  private _answer?: boolean;

  public get parent(): TransformNode | undefined {
    return this._parent;
  }

  public get isOpen(): boolean {
    return this._isOpen;
  }

  public get answer(): boolean | undefined {
    return this._answer;
  }

  constructor(model: AskIfAcceptWindowModel) {
    this._model = model;

    makeAutoObservable(this, {
      setIsOpen: action,
      setAnswer: action,
    });
  }

  public setIsOpen(isOpen: boolean): void {
    this._isOpen = isOpen;
  }

  public setAnswer(answer: boolean): void {
    this._answer = answer;
  }

  /**
   * Установить родителя объекта
   */
  public moveTo(parent: TransformNode): void {
    this._parent = parent;
  }
}
