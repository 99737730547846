import {
  AbstractMesh,
  Color3,
  Scene,
  StandardMaterial,
  TransformNode,
} from '@babylonjs/core';

import { CommonPlaneModel } from '../../models';

import { AskIfAcceptWindowConfig } from './types';

export class AskIfAcceptWindowModel {
  private _plane: CommonPlaneModel;
  private _material: StandardMaterial;

  public get root(): TransformNode {
    return this._plane.root;
  }

  public get mesh(): AbstractMesh {
    return this._plane.plane;
  }

  public get material(): StandardMaterial {
    return this._material;
  }

  constructor(scene: Scene, plane: CommonPlaneModel) {
    this._plane = plane;

    this._material = new StandardMaterial('AskIfAcceptWindow', scene);
    this._material.backFaceCulling = false;
    this._material.diffuseColor = Color3.Black();
    this._material.specularColor = Color3.Black();
    this._plane.plane.material = this._material;
  }

  static async load(
    scene: Scene,
    cfg: AskIfAcceptWindowConfig
  ): Promise<AskIfAcceptWindowModel> {
    const plane = await CommonPlaneModel.load(scene, {
      width: cfg.width,
      height: cfg.height,
    });

    return new AskIfAcceptWindowModel(scene, plane);
  }

  public setVisibility(isVisible: boolean): void {
    this._plane.setVisibility(isVisible);
  }
}
