import { makeAutoObservable } from 'mobx';

import { IKitchenAllStore, KitchenAllStore } from '../kitchen-all';

import { FurnitureType } from '../types';

export type FurnitureStoreList = {
  [key in FurnitureType]: unknown | undefined;
};

export interface IFurnitureStore {
  /**
   * Кухня
   */
  get kitchenAll(): IKitchenAllStore | undefined;
}

export class FurnitureStore implements IFurnitureStore {
  private _stores: FurnitureStoreList;

  /**
   * Чайник
   */
  public get kitchenAll(): KitchenAllStore | undefined {
    if (this._stores.kitchenAll === undefined) return undefined;
    return this._stores.kitchenAll as KitchenAllStore;
  }

  constructor(stores: FurnitureStoreList) {
    this._stores = stores;

    makeAutoObservable(this);
  }
}
