import { autorun, reaction } from 'mobx';

import { LessonStore } from 'features/lesson-page/store';

import { KitchenAllConfig } from './types';
import { KitchenAllStore } from './store';

import { HandType } from '../../player/hands';

enum IntersectionTargetId {
  TAP_HANDLE_BBOX,
}

export function setupLogic(
  store: LessonStore,
  objStore: KitchenAllStore,
  cfg: KitchenAllConfig
): void {
  const stCore = store.objects.core;
  const stPlayer = store.objects.player;
  const stWorld = store.objects.world;
  if (!stCore) throw Error('Cannot configure without objects.core');
  if (!stPlayer) throw Error('Cannot configure without objects.player');
  if (!stWorld) throw Error('Cannot configure without objects.world');

  const { xr } = stCore;
  const { hands } = stPlayer;
  const { landscape } = stWorld;
  if (!xr) throw Error('Cannot configure without objects.core.xr');
  if (!hands) throw Error('Cannot configure without objects.player.hands');
  if (!landscape)
    throw Error('Cannot configure without objects.world.landscape');

  objStore.setVisibility(true);
  objStore.moveToLandscape(landscape.model.mainHelper);

  // Установка целевых объектов для intersectHands
  autorun(() => {
    objStore.intersectHands.setTargets1([
      { id: HandType.MAIN, mesh: hands.mainHand.model.bbox },
      { id: HandType.MINOR, mesh: hands.minorHand.model.bbox },
    ]);
    objStore.intersectHands.setTargets2([
      {
        id: IntersectionTargetId.TAP_HANDLE_BBOX,
        mesh: objStore.model.tapHandleBbox,
      },
    ]);
    objStore.intersectHands.setEnabled(true);
  });

  const onStandardTriggerDown = (isLeft: boolean): void => {
    const hand = isLeft ? hands.left : hands.right;

    // Проверка есть ли пересечение руки, на кнопку которой нажали, с кучкой крана
    if (
      objStore.intersectHands.isEnabled &&
      objStore.intersectHands.has(
        hand.type,
        IntersectionTargetId.TAP_HANDLE_BBOX
      )
    ) {
      objStore.toggleTap();
    }
  };

  reaction(
    () => xr.controllers.left?.buttons.standardTriggerDown,
    (isDown) => isDown && onStandardTriggerDown(true)
  );
  reaction(
    () => xr.controllers.right?.buttons.standardTriggerDown,
    (isDown) => isDown && onStandardTriggerDown(false)
  );
}
