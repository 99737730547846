import { DebugLayer, Engine, Scene, UniversalCamera } from '@babylonjs/core';

import { ObjectsObject } from '../objects';

import { FullscreenPanelObject } from '../objects/fullscreen-panel';

export class LessonScene {
  private _engine: Engine;
  private _scene: Scene;

  public camera: UniversalCamera | null = null;

  public fullscreenPanel?: FullscreenPanelObject;

  public objects?: ObjectsObject;

  public get babylonScene(): Scene {
    return this._scene;
  }

  public get debugLayer(): DebugLayer {
    return this._scene.debugLayer;
  }

  constructor(engine: Engine, scene: Scene) {
    this._engine = engine;
    this._scene = scene;
  }

  public runRenderLoop(): void {
    this._engine.runRenderLoop(() => {
      this._scene.render();
    });
  }

  public resize(): void {
    this._engine.resize();
  }
}
