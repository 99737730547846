import { ModelConfig } from '../../common/types';

export type KettleConfig = {
  model: ModelConfig;
};

export enum KettlePlace {
  UNKNOWN,
  IN_MAIN_HAND,
  IN_MINOR_HAND,
  ON_BASE,
  ON_TABLE,
}
