import { action, makeAutoObservable } from 'mobx';

import { HandType } from '../types';
import { HandsModel, IHandsModel } from '../model';
import { ISingleHandStore, SingleHandStore } from './hand';
import { MainHandsStore } from './mainHand';

export { SingleHandStore } from './hand';
export type { ISingleHandStore } from './hand';

export interface IHandsStore {
  /**
   * Модель объекта
   */
  get model(): IHandsModel;

  /**
   * Является ли правая рука главной
   */
  get rightHandIsMain(): boolean;

  /**
   * Является ли левая рука главной
   */
  get leftHandIsMain(): boolean;

  /**
   * Массив рук
   */
  get list(): ISingleHandStore[];

  /**
   * Левая рука
   */
  get left(): ISingleHandStore;

  /**
   * Правая рука
   */
  get right(): ISingleHandStore;

  /**
   * Главная рука
   */
  get mainHand(): ISingleHandStore;

  /**
   * Второстепенная рука
   */
  get minorHand(): ISingleHandStore;

  /**
   * Анимируется ли сейчас модель
   */
  get animating(): boolean;

  /**
   * Получить нужную руку
   */
  getHand(hand: HandType): ISingleHandStore;

  /**
   * Получить противоположную руку
   */
  getOppositeHand(hand: HandType): ISingleHandStore;
}

export class HandsStore implements IHandsStore {
  private _model: HandsModel;
  private _mainHand: MainHandsStore;
  private _left: SingleHandStore;
  private _right: SingleHandStore;

  public get model(): HandsModel {
    return this._model;
  }

  public get rightHandIsMain(): boolean {
    return this._mainHand.rightHandIsMain;
  }

  public get leftHandIsMain(): boolean {
    return this._mainHand.leftHandIsMain;
  }

  public get list(): SingleHandStore[] {
    return [this._left, this._right];
  }

  public get left(): SingleHandStore {
    return this._left;
  }

  public get right(): SingleHandStore {
    return this._right;
  }

  public get mainHand(): SingleHandStore {
    return this._mainHand.leftHandIsMain ? this._left : this._right;
  }

  public get minorHand(): SingleHandStore {
    return this._mainHand.leftHandIsMain ? this._right : this._left;
  }

  public get animating(): boolean {
    return this._left.animating || this._right.animating;
  }

  constructor(model: HandsModel) {
    this._model = model;
    this._mainHand = new MainHandsStore();
    this._left = new SingleHandStore(0, this.model.left, this._mainHand, false);
    this._right = new SingleHandStore(
      1,
      this.model.right,
      this._mainHand,
      true
    );

    makeAutoObservable(this, {
      setVisibility: action,
      setGloveVisibility: action,
      setMainHand: action,
      swapMainHand: action,
    });
  }

  /**
   * Установить видемость объекта
   * @param isVisible виден ли объект
   */
  public setVisibility(isVisible: boolean): void {
    this._left.setVisibility(isVisible);
    this._right.setVisibility(isVisible);
  }

  /**
   * Установить видемость перчатки
   * @param isVisible видна ли перчатка
   */
  public setGloveVisibility(isVisible: boolean): void {
    this._left.setGloveVisibility(isVisible);
    this._right.setGloveVisibility(isVisible);
  }

  /**
   * Установить главную руку
   * @param rightHandIsMain главная рука правая?
   */
  public setMainHand(rightHandIsMain: boolean): void {
    this._mainHand.setMainHand(rightHandIsMain);
  }

  /**
   * Поменять главную руку на противоположную
   */
  public swapMainHand(): void {
    this._mainHand.setMainHand(!this._mainHand.rightHandIsMain);
  }

  public getHand(hand: HandType): SingleHandStore {
    if (hand === HandType.MAIN) return this.mainHand;
    return this.minorHand;
  }

  public getOppositeHand(hand: HandType): SingleHandStore {
    if (hand === HandType.MAIN) return this.minorHand;
    return this.mainHand;
  }
}
