import { action, makeAutoObservable } from 'mobx';

import { TransformNode, Vector3 } from '@babylonjs/core';

import { IntersectionStore } from '../../common/intersection';

import { IKitchenAllModel, KitchenAllModel } from './model';
import { IntersectionVisualCheckStore } from '../../common/intersection-visual-check';
import { WaterJetState } from './types';

export interface IKitchenAllStore {
  /**
   * Модель
   */
  get model(): IKitchenAllModel;

  /**
   * Виден ли предмет
   */
  get isVisible(): boolean;

  /**
   * Открыт ли кран
   */
  get isTapOpen(): boolean;

  /**
   * Есть ли препятствие для струи воды (чайник)
   */
  get hasWaterObstacle(): boolean;

  /**
   * Все ли визуальные элементы электро щита проверены
   */
  get areAllChecked(): boolean;
}

export class KitchenAllStore implements IKitchenAllStore {
  private _model: KitchenAllModel;
  private _visible = false;
  private _isTapOpen = false;
  private _hasWaterObstacle = false;
  private _visualCheck: IntersectionVisualCheckStore;

  private _intersectHands = new IntersectionStore();

  public get model(): KitchenAllModel {
    return this._model;
  }

  public get visualCheck(): IntersectionVisualCheckStore {
    return this._visualCheck;
  }

  public get intersectHands(): IntersectionStore {
    return this._intersectHands;
  }

  public get isVisible(): boolean {
    return this._visible;
  }

  public get areAllChecked(): boolean {
    return this._visualCheck.allMarked;
  }

  public get isTapOpen(): boolean {
    return this._isTapOpen;
  }

  public get hasWaterObstacle(): boolean {
    return this._hasWaterObstacle;
  }

  /**
   * Текущее состояние струи воды
   */
  public get waterJetState(): WaterJetState {
    if (!this._isTapOpen) return WaterJetState.CLOSED;
    return this._hasWaterObstacle
      ? WaterJetState.HALF_JET
      : WaterJetState.FULL_JET;
  }

  constructor(model: KitchenAllModel) {
    this._model = model;
    this._visualCheck = new IntersectionVisualCheckStore({
      meshes: this._model.visualCheckBbox,
    });
    this._visualCheck.setEnabled(true);
    makeAutoObservable(this, {
      setVisibility: action,
      moveToLandscape: action,
    });
  }

  /**
   * Установить виден ли предмет
   */
  public setVisibility(isVisible: boolean): void {
    this._visible = isVisible;
  }

  /**
   * Переместить объект на Landscape
   */
  public moveToLandscape(parent: TransformNode | null): void {
    this._model.root.parent = parent;
    this._model.root.position = new Vector3(0, 0, 0);
    this._model.root.rotation = new Vector3(0, 0, 0);
  }

  /**
   * Переключить состояние крана на противоположное
   */
  public toggleTap(): void {
    this._isTapOpen = !this._isTapOpen;
  }

  /**
   * Установить есть ли препятствие для воды (чайник)
   */
  public setHasWaterObstacle(hasWaterObstacle: boolean): void {
    this._hasWaterObstacle = hasWaterObstacle;
  }
}
