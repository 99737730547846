import { HighlightLayer, Scene } from '@babylonjs/core';
import { autorun } from 'mobx';

import { BaseController } from '../base';
import { IntersectionController } from '../intersection/object';
import { IntersectionVisualCheckConfig } from './types';
import { IntersectionVisualCheckStore } from './store';
import { setupLogic } from './logic';

export class IntersectionVisualCheckController extends BaseController<
  IntersectionVisualCheckStore,
  IntersectionVisualCheckConfig
> {
  private _highlightLayer: HighlightLayer;
  private _intersect: IntersectionController;

  constructor(
    scene: Scene,
    store: IntersectionVisualCheckStore,
    cfg: IntersectionVisualCheckConfig
  ) {
    super(scene, store, cfg);
    this._setLogicFunc(setupLogic);

    this._highlightLayer = new HighlightLayer('visual-check', scene);

    this._intersect = new IntersectionController(scene, store.intersect);
    this.registerController(this._intersect);
  }

  protected _connectToStore(
    store: IntersectionVisualCheckStore,
    cfg: IntersectionVisualCheckConfig
  ): void {
    autorun(() => {
      store.intersect.setTargets2(
        store.model.meshes.map((mesh, id) => ({ id, mesh }))
      );
    });
    autorun(() => {
      store.intersect.setEnabled(store.isEnabled);
    });
    autorun(() => {
      if (!store.isEnabled) return;
      store.intersect.marks.forEach(([_, id2]) => store.addMark(id2));
    });
    autorun(() => {
      store.model.meshes.forEach((m, id) => {
        if (store.isEnabled && !store.marks.has(id))
          this._highlightLayer.addMesh(m, cfg.highlight_color);
        else this._highlightLayer.removeMesh(m);
      });
      store.intersect.marks.forEach(([_, id2]) => store.addMark(id2));
    });
  }
}
