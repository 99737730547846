import { makeAutoObservable } from 'mobx';

import { CoreStore, ICoreStore } from '../core';
import { FurnitureStore, IFurnitureStore } from '../furniture';
import { IKitchenwareStore, KitchenwareStore } from '../kitchenware';
import { IPlayerStore, PlayerStore } from '../player';
import { ISceneStore, SceneStore } from '../scene';
import { IWorldStore, WorldStore } from '../world';

import { ObjectsType } from '../types';

export type ObjectsStoreList = {
  [key in ObjectsType]: unknown | undefined;
};

export interface IObjectsStore {
  /**
   * Ядро системы
   */
  get core(): ICoreStore | undefined;

  /**
   * Мебель
   */
  get furniture(): IFurnitureStore | undefined;

  /**
   * Куханная утварь
   */
  get kitchenware(): IKitchenwareStore | undefined;

  /**
   * Игрок
   */
  get player(): IPlayerStore | undefined;

  /**
   * Сцена
   */
  get scene(): ISceneStore | undefined;

  /**
   * Мир
   */
  get world(): IWorldStore | undefined;
}

export class ObjectsStore implements IObjectsStore {
  private _stores: ObjectsStoreList;

  public get core(): CoreStore | undefined {
    if (this._stores.core === undefined) return undefined;
    return this._stores.core as CoreStore;
  }

  public get furniture(): FurnitureStore | undefined {
    if (this._stores.core === undefined) return undefined;
    return this._stores.furniture as FurnitureStore;
  }

  public get kitchenware(): KitchenwareStore | undefined {
    if (this._stores.kitchenware === undefined) return undefined;
    return this._stores.kitchenware as KitchenwareStore;
  }

  public get player(): PlayerStore | undefined {
    if (this._stores.player === undefined) return undefined;
    return this._stores.player as PlayerStore;
  }

  public get scene(): SceneStore | undefined {
    if (this._stores.scene === undefined) return undefined;
    return this._stores.scene as SceneStore;
  }

  public get world(): WorldStore | undefined {
    if (this._stores.world === undefined) return undefined;
    return this._stores.world as WorldStore;
  }

  constructor(stores: ObjectsStoreList) {
    this._stores = stores;

    makeAutoObservable(this);
  }
}
