import {
  AbstractMesh,
  ISceneLoaderAsyncResult,
  Mesh,
  Scene,
  TransformNode,
} from '@babylonjs/core';

import { findByName, loadMesh } from '../../common/utils';

import { KettleBaseConfig } from './types';

export interface IKettleBaseModel {
  /**
   * bbox снизу подставки для проверки пересечения со столом
   */
  get baseBbox(): AbstractMesh;

  /**
   * bbox для проверки пересечения руки с подставкой. Используется для взятия подставки в руку
   */
  get takeBbox(): AbstractMesh;

  /**
   * bbox для проверки пересечения чайника с подставкой. Используется для утсановки чайника на подставку
   */
  get kettleBbox(): AbstractMesh;

  /**
   * helper, к которому может быть привязан чайник
   */
  get kettleHelper(): TransformNode;
}

export class KettleBaseModel implements IKettleBaseModel {
  public static readonly MODEL_VERSION = 'v0004';

  private _root: AbstractMesh;

  private _baseBbox: AbstractMesh;
  private _takeBbox: AbstractMesh;
  private _kettleBbox: AbstractMesh;
  private _visualCheckBbox: Mesh[];
  private _kettleHelper: TransformNode;

  private _allMeshes: AbstractMesh[];

  /**
   * Корень, за который можно привязывать модель
   */
  public get root(): TransformNode {
    return this._root;
  }

  public get baseBbox(): AbstractMesh {
    return this._baseBbox;
  }

  public get takeBbox(): AbstractMesh {
    return this._takeBbox;
  }

  public get kettleBbox(): AbstractMesh {
    return this._kettleBbox;
  }

  public get kettleHelper(): TransformNode {
    return this._kettleHelper;
  }

  /**
   * Набор bbox'ов для визуальной проверки подставки
   */
  public get visualCheckBbox(): Mesh[] {
    return this._visualCheckBbox;
  }

  constructor(scene: Scene, model: ISceneLoaderAsyncResult) {
    this._allMeshes = model.meshes;
    this._root = findByName(model.meshes, 'Stand');

    this._baseBbox = findByName(model.meshes, 'Bbox_TableCrossing');
    this._takeBbox = findByName(model.meshes, 'Bbox_HandCrossing');
    this._kettleBbox = findByName(model.meshes, 'Bbox_KettleCrossing');

    this._kettleHelper = findByName(model.transformNodes, 'Stand_Helper');
    this._root.position.y = 1.2;
    this._visualCheckBbox = model.meshes
      .filter((m) => m.name.startsWith('Bbox_VisualInspection'))
      .map((m) => m as Mesh);
    if (this._visualCheckBbox.length === 0)
      throw Error('Visual check bboxes (visualCheckBbox) not found');
    this.setVisibility(false);
  }

  static async load(
    cfg: KettleBaseConfig,
    scene: Scene
  ): Promise<KettleBaseModel> {
    const res = await loadMesh(scene, cfg.model, this.MODEL_VERSION);
    return new KettleBaseModel(scene, res);
  }

  public setVisibility(isVisible: boolean): void {
    for (const m of this._allMeshes) m.isVisible = isVisible;
    this._baseBbox.isVisible = false;
    this._takeBbox.isVisible = false;
    this._kettleBbox.isVisible = false;
  }
}
