import { autorun } from 'mobx';

import { TransformNode } from '@babylonjs/core';

import { LessonStore } from 'features/lesson-page/store';

import { AskIfAcceptPanelConfig } from './types';
import { AskIfAcceptPanelStore } from './store';
import { findInStore } from '../../common/utils';

export function setupLogic(
  store: LessonStore,
  objStore: AskIfAcceptPanelStore,
  cfg: AskIfAcceptPanelConfig
): void {
  const parent = findInStore(store, cfg.parent);
  if (!(parent instanceof TransformNode))
    throw Error(
      `Selected parent (${cfg.parent}) must be of type TransformNode,` +
        ` but it has type ${typeof parent}`
    );
  objStore.moveTo(parent);

  autorun(() => {
    objStore.setIsOpen(
      store.script.currentStep?.stepNumber === cfg.showOnlyOnStep
    );
  });
}
