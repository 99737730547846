import { Layout } from 'antd';
import { observer } from 'mobx-react';
import { useRootData } from 'hooks';
import React, { FC, useEffect } from 'react';

import { AvailableLesson } from '../components';

import css from './index.module.css';

const LessonPage: FC = observer(() => {
  const { fetchLessonScript } = useRootData((store) => ({
    fetchLessonScript: store.lesson.fetchLessonScript,
  }));

  useEffect(() => {
    fetchLessonScript();
  }, [fetchLessonScript]);

  return (
    <Layout className={css.layout}>
      <AvailableLesson />
    </Layout>
  );
});

export default LessonPage;
