import { Scene } from '@babylonjs/core';

import { BaseModelObject } from '../../../common/base';

import { HandsConfig } from '../types';
import { HandsModel } from '../model';
import { HandsStore } from '../store';
import { SingleHandController } from './hand';
import { setupLogic } from '../logic';

export class HandsObject extends BaseModelObject<
  HandsModel,
  HandsStore,
  HandsConfig
> {
  private _left: SingleHandController;
  private _right: SingleHandController;

  constructor(
    scene: Scene,
    model: HandsModel,
    store: HandsStore,
    cfg: HandsConfig
  ) {
    super(scene, model, store, cfg);
    this._setLogicFunc(setupLogic);

    this._left = new SingleHandController(scene, model.left, store.left);
    this._right = new SingleHandController(scene, model.right, store.right);
    this.registerController(this._left);
    this.registerController(this._right);
  }

  /**
   * Скачать все модели и создать объект
   */
  public static async setup(
    scene: Scene,
    cfg: HandsConfig
  ): Promise<HandsObject> {
    const model = await HandsModel.load(cfg, scene);
    const store = new HandsStore(model);
    return new HandsObject(scene, model, store, cfg);
  }
}
