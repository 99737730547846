import {
  GLTFFileLoader,
  GLTFLoaderAnimationStartMode,
} from '@babylonjs/loaders';
import {
  ISceneLoaderAsyncResult,
  ISceneLoaderPlugin,
  Observer,
  Scene,
  SceneLoader,
  Texture,
} from '@babylonjs/core';

// eslint-disable-next-line import/no-cycle
import { LessonStore } from 'features/lesson-page/store';

import { ModelConfig } from './types';

// Выполнен ли патч GLTFLoader
let gltfLoaderPatcher: Observer<ISceneLoaderPlugin> | null;

/**
 * Выполнить патч GLTFLoader
 *
 * Отключить воспроизведение первой анимации при загрузке меша
 */
function patchGLTFLoader() {
  if (gltfLoaderPatcher) return;

  gltfLoaderPatcher = SceneLoader.OnPluginActivatedObservable.add((p) => {
    const plugin = p;
    if (plugin.name === 'gltf' && plugin instanceof GLTFFileLoader) {
      // Do not play the first animation after loading
      plugin.animationStartMode = GLTFLoaderAnimationStartMode.NONE;
    }
  });
}

export function joinUrl(baseUrl: string, ...parts: string[]): string {
  if (parts.length === 0) return baseUrl;

  if (baseUrl[baseUrl.length - 1] === '/') baseUrl = baseUrl.slice(0, -1);

  let lastPart = parts[parts.length - 1];
  if (lastPart[0] !== '/') lastPart = `/${lastPart}`;

  return (
    baseUrl +
    parts
      .slice(0, -1)
      .map((p) => {
        if (p.length < 1) return '/';
        if (p[0] !== '/') p = `/${p}`;
        if (p[p.length - 1] === '/') return p.slice(0, -1);
        return p;
      })
      .join('') +
    lastPart
  );
}

/**
 * Получить путь до папки с данными объекта.
 * Путь формируется в виде baseUrl/version/folder/
 */
export function getRootUrlForObject(
  baseUrl: string,
  version: string,
  folder = ''
): string {
  if (folder.length > 0 && folder[folder.length - 1] !== '/')
    folder = `${folder}/`;
  return joinUrl(baseUrl, version, folder);
}

/**
 * Асинхронная загрузка меша
 */
export function loadMesh(
  scene: Scene,
  cfg: ModelConfig,
  version: string
): Promise<ISceneLoaderAsyncResult> {
  patchGLTFLoader();

  const rootUrl = getRootUrlForObject(cfg.root_url, version, cfg.folder);
  return SceneLoader.ImportMeshAsync(null, rootUrl, cfg.filename, scene);
}

/**
 * Найти и вернуть элемент с именем name в массиве элементов
 *
 * В случае неудачи будет выброшено исключение Error
 * @param elements массив элементов для поиска
 * @param name имя элемента, который необходимо найти
 * @returns Искомый элемент с заданным именем
 */
export function findByName<T extends { name: string }>(
  elements: T[],
  name: string
): T {
  const targetMeshes = elements.filter((el) => el.name === name);
  if (targetMeshes.length === 1) return targetMeshes[0];
  if (targetMeshes.length < 1)
    throw Error(`Element with name '${name}' not found`);
  throw Error(
    `Found ${targetMeshes.length} elements with name '${name}' (should be 1)`
  );
}

/**
 * Функция, которая доставет искомый путь из store.
 *
 * Конкретно сейчас все пути захардкожены, однако в будущем это будет исправлено
 */
export function findInStore(
  store: LessonStore,
  path: string
): undefined | unknown {
  switch (path) {
    case 'objects.world.landscape.model.mainHelper':
      return store.objects.world?.landscape?.model.mainHelper;
    case 'objects.scene.askIfAcceptPanel1.answer':
      return store.objects.scene?.askIfAcceptPanel1?.answer;
    case 'objects.scene.askIfAcceptPanel2.answer':
      return store.objects.scene?.askIfAcceptPanel2?.answer;
  }
  return undefined;
}

/**
 * Асинхронная загрузка текстуры
 * @param url defines the url of the picture to load as a texture
 * @param scene defines the scene the texture will belong to
 * @param noMipmap defines if the texture will require mip maps or not
 * @param invertY defines if the texture needs to be inverted on the y axis during loading
 * @param samplingMode defines the sampling mode we want for the texture while fectching from it (Texture.NEAREST_SAMPLINGMODE...)
 */
export function loadTexture(
  url: string,
  scene: Scene,
  noMipmap?: boolean,
  invertY?: boolean,
  samplingMode?: number
): Promise<Texture> {
  return new Promise<Texture>((resolve, reject) => {
    const res = new Texture(
      url,
      scene,
      noMipmap,
      invertY,
      samplingMode,
      () => {
        resolve(res);
      },
      (msg) => {
        reject(msg);
      }
    );
  });
}

export function loadJSImage(url: string): Promise<HTMLImageElement> {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const res = new window.Image();
    res.crossOrigin = 'anonymous'; // FIXME: у репозитория неправильно настроен CORS
    res.onload = () => {
      resolve(res);
    };
    res.onerror = (e) => {
      reject(e);
    };
    res.src = url;
  });
}
