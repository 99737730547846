import { autorun } from 'mobx';

import { Color3, Scene } from '@babylonjs/core';

import { BaseModelObject } from '../../common/base';
import { IntersectionController } from '../../common/intersection';

import { IntersectionVisualCheckController } from '../../common/intersection-visual-check';
import { KitchenAllConfig } from './types';
import { KitchenAllModel } from './model';
import { KitchenAllStore } from './store';
import { setupLogic } from './logic';

export class KitchenAllObject extends BaseModelObject<
  KitchenAllModel,
  KitchenAllStore,
  KitchenAllConfig
> {
  private _intersectHands: IntersectionController;
  private _visualCheck: IntersectionVisualCheckController;

  constructor(
    scene: Scene,
    model: KitchenAllModel,
    store: KitchenAllStore,
    cfg: KitchenAllConfig
  ) {
    super(scene, model, store, cfg);
    this._setLogicFunc(setupLogic);

    this._intersectHands = new IntersectionController(
      scene,
      store.intersectHands
    );
    this._visualCheck = new IntersectionVisualCheckController(
      scene,
      store.visualCheck,
      {
        highlight_color: Color3.Green(),
        empty_hands_only: true,
      }
    );
    this.registerController(this._intersectHands);
    this.registerController(this._visualCheck);
  }

  public static async setup(
    scene: Scene,
    cfg: KitchenAllConfig
  ): Promise<KitchenAllObject> {
    const model = await KitchenAllModel.load(cfg, scene);
    const store = new KitchenAllStore(model);
    return new KitchenAllObject(scene, model, store, cfg);
  }

  protected _connectToStore(
    store: KitchenAllStore,
    cfg: KitchenAllConfig
  ): void {
    autorun(() => {
      this.model.setVisibility(store.isVisible);
    });
    autorun(() => {
      this.model.setTapIsOpen(store.isTapOpen);
    });
    autorun(() => {
      this.model.setWaterJetStatus(store.waterJetState);
    });
  }
}
