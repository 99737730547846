import { Script } from 'api';

export const mockScript: Script = {
  id: 'ff7045b8-c7b7-43dd-babe-955bb3708776',
  name: 'DemoSibur',
  description:
    'Пример разрабатываемого сценария для тренажера (Демо-версия на примере электрического чайника)',
  image:
    'https://fs.devogip.ru/api/File/vr-training?objectName=scripts/demo-sibur/preview.jpg',
  steps: [
    {
      name: 'Возьмите чайник в руку',
      audioUrl: '',
      shortHint: 'Возьмите чайник в руку',
      requiredStates: [],
      doneCondition: {
        stateName: 'isKettleInHand',
        stateValue: true,
      },
      stepNumber: 1,
    },
    {
      name: 'Визуальная проверка состояния чайника',
      audioUrl: '',
      shortHint: 'Осмотрите чайник на наличие повреждений',
      requiredStates: [],
      doneCondition: {
        stateName: 'areAllLabelsTouched',
        stateValue: true,
      },
      stepNumber: 2,
    },
    {
      name: 'Поставьте чайник на стол',
      audioUrl: '',
      shortHint: 'Поставьте чайник на стол',
      requiredStates: [],
      doneCondition: {
        stateName: 'isKettleOnTable',
        stateValue: true,
      },
      stepNumber: 3,
    },
    {
      name: 'Откройте крышку нажав на кнопку крышки',
      audioUrl: '',
      shortHint: 'Откройте крышку нажав на кнопку крышки',
      requiredStates: [],
      doneCondition: {
        stateName: 'isKettleOpen',
        stateValue: true,
      },
      stepNumber: 4,
    },
    {
      name: 'Возьмите в руку фильтр и осмотрите его',
      audioUrl: '',
      shortHint: 'Возьмите в руку фильтр и осмотрите его',
      requiredStates: [],
      doneCondition: {
        stateName: 'isKettleFilterInHand',
        stateValue: true,
      },
      stepNumber: 5,
    },
    {
      name: 'Поставьте фильтр на свое место в чайник',
      audioUrl: '',
      shortHint: 'Поставьте фильтр на свое место в чайник',
      requiredStates: [],
      doneCondition: {
        stateName: 'isKettleFilterInKettle',
        stateValue: true,
      },
      stepNumber: 6,
    },
    {
      name: 'Возьмите в руку подставку чайника со шнуром питания',
      audioUrl: '',
      shortHint: 'Возьмите в руку подставку чайника со шнуром питания',
      requiredStates: [],
      doneCondition: {
        stateName: 'isKettleBaseInHand',
        stateValue: true,
      },
      stepNumber: 7,
    },
    {
      name: 'Визуальный осмотр подставки чайника со шнуром питания',
      audioUrl: '',
      shortHint:
        'Осмотрите подставку чайника со шнуром питания на наличие повреждений',
      requiredStates: [],
      doneCondition: {
        stateName: 'isKettleBaseLabelsTouched',
        stateValue: true,
      },
      stepNumber: 8,
    },
    {
      name: 'Положите подставку чайника со шнуром питания на стол рабом с чайником',
      audioUrl: '',
      shortHint:
        'Положите подставку чайника со шнуром питания на стол рабом с чайником',
      requiredStates: [],
      doneCondition: {
        stateName: 'isKettleBaseOnTable',
        stateValue: true,
      },
      stepNumber: 9,
    },
    {
      name: 'Сверьте данные мощности в чайнике с допустимой мощностью электрической сети. Для этого возьмите чайник в руки посмотрите на маркировку. Также подойдите к электрическому щитку и проверьте маркировку на электрическом автомате',
      audioUrl: '',
      shortHint:
        'Сверьте данные мощности в чайнике с допустимой мощностью электрической сети. Для этого возьмите чайник в руки посмотрите на маркировку. Также подойдите к электрическому щитку и проверьте маркировку на электрическом автомате',
      requiredStates: [],
      doneCondition: {
        stateName: 'isCorrectPower',
        stateValue: true,
      },
      stepNumber: 10,
    },
    {
      name: 'Убедиться, что мощность чайника допустима для текущей электрической сети кухни.',
      audioUrl: '',
      shortHint:
        'Убедиться, что мощность чайника допустима для текущей электрической сети кухни.',
      requiredStates: [],
      doneCondition: {
        stateName: '[objects.scene.askIfAcceptPanel1.answer]',
        stateValue: false,
      },
      stepNumber: 11,
    },
    {
      name: 'Наполнение чайника водой',
      audioUrl: '',
      shortHint:
        'Поднесите чайник под кран. Откройте кран. Закройте кран по факту наполнения чайника водой ',
      requiredStates: [],
      doneCondition: {
        stateName: 'isKettleFilled',
        stateValue: true,
      },
      stepNumber: 12,
    },
    {
      name: 'Закрыть крышку чайника',
      audioUrl: '',
      shortHint: '.Закрыть крышку чайника',
      requiredStates: [],
      doneCondition: {
        stateName: 'isKettleOpen',
        stateValue: false,
      },
      stepNumber: 13,
    },
    {
      name: 'Проверка наличия протечек воды из чайника.',
      audioUrl: '',
      shortHint: 'Осмотрите чайник на наличие протечек воды',
      requiredStates: [],
      doneCondition: {
        stateName: 'areAllLabelsTouched',
        stateValue: true,
      },
      stepNumber: 14,
    },
    {
      name: 'Установите чайник на подставку',
      audioUrl: '',
      shortHint: 'Установите чайник на подставку',
      requiredStates: [],
      doneCondition: {
        stateName: 'isKettleOnBase',
        stateValue: true,
      },
      stepNumber: 15,
    },
    {
      name: 'Проверка соответствия уровня воды допустимым пределам',
      audioUrl: '',
      shortHint: 'Уровень воды выше отметки MIN и ниже отметки MAX',
      requiredStates: [],
      doneCondition: {
        stateName: '[objects.scene.askIfAcceptPanel2.answer]',
        stateValue: true,
      },
      stepNumber: 16,
    },
  ],
  config: {
    core: {
      xr: {},
    },
    scene: {
      infoPanel: {
        parent: 'objects.world.landscape.model.mainHelper',
        position: {
          x: 1.2,
          y: 1.4,
          z: -0.2,
        },
        rotation: {
          x: 0,
          y: -1,
          z: 0,
        },
      },
      askIfAcceptPanel1: {
        showOnlyOnStep: 11,
        window: {
          height: 0.7,
          width: 0.8,
          text: {
            windowTitle: 'Кто хочет стать миллионером?',
            question:
              'Согласно указанным значениям подключение чайника вызовет перегрузку сети кухни?',
            acceptButton: 'Да',
            rejectButton: 'Нет',
          },
          dpi: 30,
          uiScale: 1,
        },
        parent: 'objects.world.landscape.model.mainHelper',
        position: {
          x: 0.0,
          y: 1.4,
          z: -0.5,
        },
        rotation: {
          x: 0,
          y: 0,
          z: 0,
        },
      },
      askIfAcceptPanel2: {
        showOnlyOnStep: 16,
        window: {
          height: 0.7,
          width: 0.8,
          text: {
            windowTitle: 'Кто хочет стать миллионером?',
            question:
              'Уровень воды в чайнике выше отметки MIN и ниже отметки MAX?',
            acceptButton: 'Да',
            rejectButton: 'Нет',
          },
          dpi: 30,
          uiScale: 1,
        },
        parent: 'objects.world.landscape.model.mainHelper',
        position: {
          x: 0.0,
          y: 1.4,
          z: -0.5,
        },
        rotation: {
          x: 0,
          y: 0,
          z: 0,
        },
      },
    },
    world: {
      lights: {
        lights: [
          {
            diraction: {
              x: -1,
              y: 1,
              z: 0.4,
            },
            intensity: 1,
          },
        ],
      },
      skybox: {
        image: {
          folder: 'roof',
          filename: 'sunset_jhbcentral_4k.jpg',
          root_url:
            'https://fs.devogip.ru/api/File/vr-training?objectName=objects/world/skybox/',
        },
      },
      landscape: {
        model: {
          folder: 'kitchen_room',
          filename: 'landscape.gltf',
          root_url:
            'https://fs.devogip.ru/api/File/vr-training?objectName=objects/world/landscape/',
        },
      },
    },
    player: {
      hands: {
        left: {
          model: {
            folder: '',
            filename: 'HandLeft.gltf',
            root_url:
              'https://fs.devogip.ru/api/File/vr-training?objectName=objects/player/hands/',
          },
        },
        right: {
          model: {
            folder: '',
            filename: 'HandRight.gltf',
            root_url:
              'https://fs.devogip.ru/api/File/vr-training?objectName=objects/player/hands/',
          },
        },
      },
      inventory: {},
    },
    furniture: {
      kitchenAll: {
        model: {
          folder: '',
          filename: 'kitchen-all.gltf',
          root_url:
            'https://fs.devogip.ru/api/File/vr-training?objectName=objects/furniture/kitchen-all/',
        },
      },
    },
    kitchenware: {
      kettle: {
        model: {
          folder: '',
          filename: 'kettle.gltf',
          root_url:
            'https://fs.devogip.ru/api/File/vr-training?objectName=objects/kitchenware/kettle/',
        },
      },
      kettleBase: {
        model: {
          folder: '',
          filename: 'kettle_base.gltf',
          root_url:
            'https://fs.devogip.ru/api/File/vr-training?objectName=objects/kitchenware/kettle-base/',
        },
      },
      kettleFilter: {
        model: {
          folder: '',
          filename: 'kettle_filter.gltf',
          root_url:
            'https://fs.devogip.ru/api/File/vr-training?objectName=objects/kitchenware/kettle-filter/',
        },
      },
    },
  },
};
