import { Scene } from '@babylonjs/core';

import { LessonStore } from 'features/lesson-page/store';

export class BaseController<StoreType, ConfigType> {
  private __scene: Scene;
  private __store: StoreType;
  private __cfg: ConfigType;

  private __logicWasSetup = false;
  private __controllers: BaseController<unknown, unknown>[] = [];
  private __setupLogicFunc = (store: LessonStore) => {};

  /**
   * Сцена, в которой создан объект
   */
  public get scene(): Scene {
    return this.__scene;
  }

  /**
   * Хранилище
   */
  public get store(): StoreType {
    return this.__store;
  }

  /**
   * Конфиги
   */
  public get cfg(): ConfigType {
    return this.__cfg;
  }

  constructor(scene: Scene, store: StoreType, cfg: ConfigType) {
    this.__scene = scene;
    this.__store = store;
    this.__cfg = cfg;
  }

  /**
   * Присоединить контроллер к его стору
   */
  // eslint-disable-next-line class-methods-use-this
  protected _connectToStore(store: StoreType, cfg: ConfigType): void {}

  /**
   * Установить функцию установки логики
   * @param setupLogicFunc функция установки логики
   */
  protected _setLogicFunc(
    setupLogicFunc: (
      store: LessonStore,
      objStore: StoreType,
      cfg: ConfigType
    ) => void
  ): void {
    this.__setupLogicFunc = (store: LessonStore) => {
      setupLogicFunc && setupLogicFunc(store, this.__store, this.__cfg);
    };
  }

  /**
   * Зарегистрировать контроллер для вызова setupLogic
   * @param controller контроллер, который будет зарегистрирован
   */
  protected registerController(
    controller: BaseController<unknown, unknown>
  ): void {
    this.__controllers.push(controller);
  }

  public setupLogic(store: LessonStore): void {
    if (this.__logicWasSetup)
      throw Error(
        'Обнаружена повторная попытка установить логику для контроллера'
      );
    this.__logicWasSetup = true;
    this._connectToStore(this.__store, this.__cfg);
    this.__setupLogicFunc(store);
    this.__controllers.forEach((c) => c.setupLogic(store));
  }
}
