import {
  AbstractMesh,
  ISceneLoaderAsyncResult,
  Scene,
  TransformNode,
} from '@babylonjs/core';

import { findByName, loadMesh } from '../../common/utils';

import { KettleFilterConfig } from './types';

export interface IKettleFilterModel {
  /**
   * bbox для проверки пересечения руки с фильтром. Используется для взятия фильтра в руку
   */
  get takeBbox(): AbstractMesh;
}

export class KettleFilterModel implements IKettleFilterModel {
  public static readonly MODEL_VERSION = 'v0003';

  private _root: TransformNode;
  private _takeBbox: AbstractMesh;
  private _allMeshes: AbstractMesh[];

  /**
   * Корень, за который можно привязывать модель
   */
  public get root(): TransformNode {
    return this._root;
  }

  public get takeBbox(): AbstractMesh {
    return this._takeBbox;
  }

  constructor(scene: Scene, model: ISceneLoaderAsyncResult) {
    this._allMeshes = model.meshes;
    this._root = findByName(model.transformNodes, 'Filter');
    this._takeBbox = findByName(model.meshes, 'Bbox_Filter');

    this.setVisibility(false);
  }

  static async load(
    cfg: KettleFilterConfig,
    scene: Scene
  ): Promise<KettleFilterModel> {
    const res = await loadMesh(scene, cfg.model, this.MODEL_VERSION);
    return new KettleFilterModel(scene, res);
  }

  public setVisibility(isVisible: boolean): void {
    for (const m of this._allMeshes) m.isVisible = isVisible;
    this._takeBbox.isVisible = false;
  }
}
