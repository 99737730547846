import { Scene } from '@babylonjs/core';

import { BaseController, BaseObject } from '../../common/base';

import { KettleBaseObject } from '../kettle-base';
import { KettleFilterObject } from '../kettle-filter';
import { KettleObject } from '../kettle';

import { KitchenwareConfig } from './types';
import { KitchenwareStore, KitchenwareStoreList } from './store';

import { KitchenwareType } from '../types';

type ObjectClassesList = {
  [key in KitchenwareType]: {
    setup: (
      scene: Scene,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cfg: any
    ) => Promise<BaseController<unknown, unknown>>;
  };
};

const objectClasses: ObjectClassesList = {
  kettle: KettleObject,
  kettleBase: KettleBaseObject,
  kettleFilter: KettleFilterObject,
};

type KitchenwareObjectList = {
  [key in KitchenwareType]: BaseController<unknown, unknown> | undefined;
};

export class KitchenwareObject extends BaseObject<
  KitchenwareStore,
  KitchenwareConfig
> {
  constructor(
    scene: Scene,
    objects: KitchenwareObjectList,
    store: KitchenwareStore,
    cfg: KitchenwareConfig
  ) {
    super(scene, store, cfg);
    for (const obj of Object.values(objects)) {
      obj && this.registerObject(obj);
    }
  }

  public static async setup(
    scene: Scene,
    cfg: KitchenwareConfig
  ): Promise<KitchenwareObject> {
    const objectKeys = Object.values(KitchenwareType);

    const objectsToLoad = [];
    for (const k of objectKeys) {
      objectsToLoad.push(
        cfg[k]
          ? objectClasses[k].setup(scene, cfg[k])
          : Promise.resolve(undefined)
      );
    }
    const loadedObjects = await Promise.all(objectsToLoad);

    // Build objects and stores
    const objects: KitchenwareObjectList = {} as KitchenwareObjectList;
    const stores: KitchenwareStoreList = {} as KitchenwareStoreList;

    for (let i = 0; i < objectKeys.length; i += 1) {
      const k = objectKeys[i];
      objects[k] = loadedObjects[i];
      stores[k] = loadedObjects[i]?.store;
    }

    return new KitchenwareObject(
      scene,
      objects,
      new KitchenwareStore(stores),
      cfg
    );
  }
}
