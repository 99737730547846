import {
  Engine,
  EngineOptions,
  Scene,
  UniversalCamera,
  Vector3,
} from '@babylonjs/core';

import { LessonStore } from 'features/lesson-page/store';

import { LessonScene } from './scene';
import { ObjectsConfig, ObjectsObject } from '../objects';
import { setupLogicForSceneState } from './logic';

function createCamera(
  scene: Scene,
  canvasElement: HTMLCanvasElement
): UniversalCamera {
  const camera = new UniversalCamera(
    'UniversalCamera',
    new Vector3(-5, 1, 0),
    scene
  );
  camera.minZ = 0.005;
  camera.fov = 0.8;
  camera.ellipsoid.set(1, 0.8, 1);

  // WASD
  camera.keysUp.push(87);
  camera.keysLeft.push(65);
  camera.keysDown.push(83);
  camera.keysRight.push(68);

  camera.checkCollisions = true;
  camera.applyGravity = true;

  camera.inertia = 0.6;
  camera.speed = 1;
  camera.setTarget(new Vector3(0, 0.8, 0));

  camera.attachControl(canvasElement, true);

  return camera;
}

export class LessonSceneBuilder {
  private _canvas: HTMLCanvasElement;
  private _engine: Engine;
  private _scene: LessonScene | null = null;

  public get scene(): LessonScene {
    if (!this._scene) throw new TypeError('Scene is null. Call .load() before');
    return this._scene;
  }

  constructor(
    canvas: HTMLCanvasElement,
    antialias?: boolean | undefined,
    options?: EngineOptions | undefined,
    adaptToDeviceRatio?: boolean | undefined
  ) {
    this._canvas = canvas;
    this._engine = new Engine(canvas, antialias, options, adaptToDeviceRatio);
  }

  /**
   * Загрузить все необходимые данные для сцены
   */
  public async load(cfg: ObjectsConfig): Promise<void> {
    // Создание сцены
    const babylonScene = new Scene(this._engine);
    const scene = new LessonScene(this._engine, babylonScene);
    this._scene = scene;

    scene.camera = createCamera(babylonScene, this._canvas);
    scene.objects = await ObjectsObject.setup(babylonScene, cfg);
  }

  /**
   * Расположить элементы сцены, привязать элемены друг к другу
   */
  public place(): void {
    const { babylonScene } = this.scene;

    babylonScene.gravity = new Vector3(0, -0.15, 0);
    babylonScene.collisionsEnabled = true;
  }

  /**
   * Задать логику работы сцены. Установить реакции на события разных элементов
   */
  public setupLogic(store: LessonStore): void {
    const { scene } = this;
    setupLogicForSceneState(scene);
    scene.objects?.setupLegacyLogic(this.scene);
    scene.objects?.setupLogic(store);
  }
}
