import { Scene } from '@babylonjs/core';

import { HandsConfig } from '../types';
import { ISingleHandModel, SingleHandModel } from './hand';

export { SingleHandModel } from './hand';

export interface IHandsModel {
  /**
   * Левая рука
   */
  get left(): ISingleHandModel;

  /**
   * Правая рука
   */
  get right(): ISingleHandModel;
}

export class HandsModel {
  private _left: SingleHandModel;
  private _right: SingleHandModel;

  public get left(): SingleHandModel {
    return this._left;
  }

  public get right(): SingleHandModel {
    return this._right;
  }

  constructor(scene: Scene, left: SingleHandModel, right: SingleHandModel) {
    this._left = left;
    this._right = right;
  }

  static async load(cfg: HandsConfig, scene: Scene): Promise<HandsModel> {
    const [left, right] = await Promise.all([
      SingleHandModel.load(cfg.left, scene),
      SingleHandModel.load(cfg.right, scene),
    ]);
    return new HandsModel(scene, left, right);
  }
}
