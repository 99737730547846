import { autorun } from 'mobx';

import { Color3, Scene } from '@babylonjs/core';

import { BaseModelObject } from '../../common/base';
import { IntersectionController } from '../../common/intersection';
import { IntersectionVisualCheckController } from '../../common/intersection-visual-check';

import { KettleConfig } from './types';
import { KettleModel } from './model';
import { KettleStore } from './store';
import { setupLogic } from './logic';

export class KettleObject extends BaseModelObject<
  KettleModel,
  KettleStore,
  KettleConfig
> {
  private _intersectBase: IntersectionController;
  private _intersectHands: IntersectionController;
  private _visualCheck: IntersectionVisualCheckController;
  private _labelVisualCheck: IntersectionVisualCheckController;
  constructor(
    scene: Scene,
    model: KettleModel,
    store: KettleStore,
    cfg: KettleConfig
  ) {
    super(scene, model, store, cfg);
    this._setLogicFunc(setupLogic);

    this._intersectBase = new IntersectionController(
      scene,
      store.intersectBase
    );
    this._intersectHands = new IntersectionController(
      scene,
      store.intersectHands
    );
    this._visualCheck = new IntersectionVisualCheckController(
      scene,
      store.visualCheck,
      {
        highlight_color: Color3.Green(),
        empty_hands_only: true,
      }
    );
    this._labelVisualCheck = new IntersectionVisualCheckController(
      scene,
      store.labelVisualCheck,
      {
        highlight_color: Color3.Green(),
        empty_hands_only: false,
      }
    );
    this.registerController(this._intersectBase);
    this.registerController(this._intersectHands);
    this.registerController(this._visualCheck);
    this.registerController(this._labelVisualCheck);
  }

  public static async setup(
    scene: Scene,
    cfg: KettleConfig
  ): Promise<KettleObject> {
    const model = await KettleModel.load(cfg, scene);
    const store = new KettleStore(model);
    return new KettleObject(scene, model, store, cfg);
  }

  protected _connectToStore(store: KettleStore, cfg: KettleConfig): void {
    autorun(() => {
      this.model.setVisibility(store.isVisible);
    });
    autorun(() => {
      if (store.isVisible) this.model.setWaterLineVisibility(store.isFilled);
    });
    autorun(() => {
      if (store.isCapOpen) this.model.pressCapButton();
      this.model.setCapOpenState(store.isCapOpen);
    });
  }
}
