import { ModelConfig } from '../../common/types';

export type KitchenAllConfig = {
  model: ModelConfig;
};

export enum WaterJetState {
  CLOSED,
  HALF_JET,
  FULL_JET,
}
