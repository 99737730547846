import { action, makeAutoObservable } from 'mobx';

import { HandType } from '../../hands';
import { ObjectsType } from '../../../types';

import { IInventoryItem, InventoryItem } from './item';
import { IInventoryWantInHandStore, InventoryItemType } from '../types';

export interface IInventoryStore {
  /**
   * Надеты ли перчатки
   */
  get gloveSelected(): boolean;

  /**
   * item в основной руке
   */
  get mainHandItem(): IInventoryItem;

  /**
   * item во второстипенной руке
   */
  get minorHandItem(): IInventoryItem;

  /**
   * Найти в какой руке находится элемент
   */
  find(group: ObjectsType, object: unknown): HandType | undefined;

  /**
   * Получить инструмент в нужной руке
   */
  getHand(hand: HandType): InventoryItem;

  /**
   * Получить инструмент в противоположной руке
   */
  getOppositeHand(hand: HandType): InventoryItem;

  /**
   * Зарегистрировать объект, который самостоятельно может захотеть попасть в руку
   */
  registerObjectWantInHand(
    obj: IInventoryWantInHandStore,
    item_type: InventoryItemType
  ): void;
}

export type WantInHandWithType = {
  obj: IInventoryWantInHandStore;
  item: InventoryItemType;
};

export class InventoryStore implements IInventoryStore {
  private _gloveSelected = false;
  private _mainHandItem = new InventoryItem();
  private _minorHandItem = new InventoryItem();
  private _objectsWantInHand: WantInHandWithType[] = [];

  public get gloveSelected(): boolean {
    return this._gloveSelected;
  }

  public get mainHandItem(): InventoryItem {
    return this._mainHandItem;
  }

  public get minorHandItem(): InventoryItem {
    return this._minorHandItem;
  }

  public get objectsWantInHand(): WantInHandWithType[] {
    return this._objectsWantInHand;
  }

  constructor() {
    makeAutoObservable(this, {
      setGloveSelected: action,
      registerObjectWantInHand: action,
    });
  }

  /**
   * Установить одеты ли перчатки
   */
  public setGloveSelected(isSelected: boolean): void {
    this._gloveSelected = isSelected;
  }

  public find(group: ObjectsType, object: unknown): HandType | undefined {
    const [main, minor] = [this.mainHandItem, this.minorHandItem];
    const inMain = main.group === group && main.object === object;
    const inMinor = minor.group === group && minor.object === object;

    if (inMain && inMinor) throw Error('Not implemented error');
    if (inMain) return HandType.MAIN;
    if (inMinor) return HandType.MINOR;
    return undefined;
  }

  public getHand(hand: HandType): InventoryItem {
    if (hand === HandType.MAIN) return this.mainHandItem;
    return this.minorHandItem;
  }

  public getOppositeHand(hand: HandType): InventoryItem {
    if (hand === HandType.MAIN) return this.minorHandItem;
    return this.mainHandItem;
  }

  public registerObjectWantInHand(
    obj: IInventoryWantInHandStore,
    item: InventoryItemType
  ): void {
    this._objectsWantInHand.push({ obj, item });
  }
}
