import { Scene } from '@babylonjs/core';
import { autorun } from 'mobx';

import { BaseModelObject } from '../../common/base';
import { LandscapeConfig } from './types';
import { LandscapeModel } from './model';
import { LandscapeStore } from './store';
import { setupLogic } from './logic';

export class LandscapeObject extends BaseModelObject<
  LandscapeModel,
  LandscapeStore,
  LandscapeConfig
> {
  constructor(
    scene: Scene,
    model: LandscapeModel,
    store: LandscapeStore,
    cfg: LandscapeConfig
  ) {
    super(scene, model, store, cfg);
    this._setLogicFunc(setupLogic);
  }

  protected _connectToStore(store: LandscapeStore, cfg: LandscapeConfig): void {
    autorun(() => {
      this.model.setVisibility(store.isVisible);
    });
    autorun(() => {
      this.model.setCollisionEnabled(store.isCollisionEnabled);
    });
  }

  /**
   * Скачать все модели и создать планшет
   */
  public static async setup(
    scene: Scene,
    cfg: LandscapeConfig
  ): Promise<LandscapeObject> {
    const model = await LandscapeModel.load(scene, cfg);
    const store = new LandscapeStore(model);
    return new LandscapeObject(scene, model, store, cfg);
  }
}
