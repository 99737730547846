import { IXRStore, XRStore } from '../xr';

export type CoreStoreList = {
  xr?: XRStore;
};

export interface ICoreStore {
  /**
   * XR
   */
  get xr(): IXRStore | undefined;
}

export class CoreStore implements ICoreStore {
  private _stores: CoreStoreList;

  public get xr(): XRStore | undefined {
    return this._stores.xr;
  }

  constructor(stores: CoreStoreList) {
    this._stores = stores;
  }
}
