import { AdvancedDynamicTexture, Vector2WithInfo } from '@babylonjs/gui';
import { Observable } from '@babylonjs/core';

import { BabylonWindow } from 'features/lesson-page/scene-components/widgets';

import { AskIfAcceptWindowConfig } from '../types';
import { WBodyAskQuestion } from './body-ask-if-accept';

export class AskIfAcceptWindowGUI {
  private _scale = 1;
  private _window: BabylonWindow;
  private _windowBody: WBodyAskQuestion;

  public onCloseButtonClickCallback = new Observable<Vector2WithInfo>();
  public onAcceptButtonClickCallback = new Observable<Vector2WithInfo>();
  public onRejectButtonClickCallback = new Observable<Vector2WithInfo>();

  public get scale(): number {
    return this._scale;
  }

  public set scale(value: number) {
    if (value === this._scale) return;
    this._scale = value;
    this._window.scale = value;
    this._windowBody.scale = value;
  }

  public get isEnabled(): boolean {
    return this._windowBody.isEnabled;
  }

  public set isEnabled(value: boolean) {
    this._windowBody.isEnabled = value;
  }

  constructor(texture: AdvancedDynamicTexture, cfg: AskIfAcceptWindowConfig) {
    this._window = new BabylonWindow({ close: false });
    this._window.titleText = cfg.text.windowTitle;
    this._windowBody = new WBodyAskQuestion(cfg);
    this._window.addControl(this._windowBody);
    texture.addControl(this._window);

    // Перенаправления нужных callback'ов
    this._window.onCButtonCloseClickObservable.add((e: Vector2WithInfo) => {
      this.onCloseButtonClickCallback.notifyObservers(e);
    });
    this._windowBody.acceptButton.onPointerClickObservable.add(
      (e: Vector2WithInfo) => {
        this.onAcceptButtonClickCallback.notifyObservers(e);
      }
    );
    this._windowBody.rejectButton.onPointerClickObservable.add(
      (e: Vector2WithInfo) => {
        this.onRejectButtonClickCallback.notifyObservers(e);
      }
    );
  }
}
