import { action, autorun } from 'mobx';

// eslint-disable-next-line import/no-cycle
import { LessonStoreInstance as lessonStore } from 'features/lesson-page/store';

import { LessonScene } from '../scene';

const setIsKettleInHand = action((v: boolean) => {
  lessonStore.sceneState.isKettleInHand = v;
});

const setAreAllLabelsTouched = action((v: boolean) => {
  lessonStore.sceneState.areAllLabelsTouched = v;
});

const setIsKettleOnTable = action((v: boolean) => {
  lessonStore.sceneState.isKettleOnTable = v;
});

const setIsKettleOpen = action((v: boolean) => {
  lessonStore.sceneState.isKettleOpen = v;
});

const setIsKettleFilterInHand = action((v: boolean) => {
  lessonStore.sceneState.isKettleFilterInHand = v;
});

const setIsKettleFilterInKettle = action((v: boolean) => {
  lessonStore.sceneState.isKettleFilterInKettle = v;
});

const setIsKettleBaseInHand = action((v: boolean) => {
  lessonStore.sceneState.isKettleBaseInHand = v;
});

const setIsKettleBaseLabelsTouched = action((v: boolean) => {
  lessonStore.sceneState.isKettleBaseLabelsTouched = v;
});

const setIsKettleBaseOnTable = action((v: boolean) => {
  lessonStore.sceneState.isKettleBaseOnTable = v;
});

const setIsKettleFilled = action((v: boolean) => {
  lessonStore.sceneState.isKettleFilled = v;
});

const setIsTapClosed = action((v: boolean) => {
  lessonStore.sceneState.isTapClosed = v;
});

const setIsKettleOnBase = action((v: boolean) => {
  lessonStore.sceneState.isKettleOnBase = v;
});

const setIsCorrectPower = action((v: boolean) => {
  lessonStore.sceneState.isCorrectPower = v;
});

/**
 * Установка логики для sceneState
 */
export function setupLogicForSceneState(scene: LessonScene): void {
  autorun(() => {
    if (!lessonStore.objects.kitchenware?.kettle) return;
    if (!lessonStore.objects.kitchenware?.kettleFilter) return;
    if (!lessonStore.objects.kitchenware?.kettleBase) return;
    if (!lessonStore.objects.furniture?.kitchenAll) return;
    setAreAllLabelsTouched(
      lessonStore.objects.kitchenware.kettle.areAllChecked
    );
    setIsKettleInHand(
      lessonStore.objects.kitchenware.kettle.isInHand !== undefined
    );

    setIsKettleOnTable(lessonStore.objects.kitchenware.kettle.isOnTable);
    setIsKettleOpen(lessonStore.objects.kitchenware.kettle.isCapOpen);
    setIsKettleFilterInHand(
      lessonStore.objects.kitchenware.kettleFilter.isInHand !== undefined
    );
    setIsKettleFilterInKettle(
      lessonStore.objects.kitchenware.kettleFilter.isInKettle
    );

    setIsKettleBaseInHand(
      lessonStore.objects.kitchenware.kettleBase.isInHand !== undefined
    );
    setIsKettleBaseLabelsTouched(
      lessonStore.objects.kitchenware.kettleBase.areAllChecked
    );
    setIsKettleBaseOnTable(
      lessonStore.objects.kitchenware.kettleBase.isOnTable
    );
    setIsKettleFilled(lessonStore.objects.kitchenware.kettle.isFilled);

    setIsTapClosed(!lessonStore.objects.furniture?.kitchenAll?.isTapOpen);
    setIsKettleOnBase(lessonStore.objects.kitchenware.kettle.isOnBase);
    setIsCorrectPower(
      lessonStore.objects.furniture.kitchenAll.areAllChecked &&
        lessonStore.objects.kitchenware.kettleBase.areAllChecked
    );
  });
}
