import { ModelConfig } from '../../common/types';

export type KettleFilterConfig = {
  model: ModelConfig;
};

export enum KettleFilterPlace {
  UNKNOWN,
  IN_MAIN_HAND,
  IN_MINOR_HAND,
  IN_KETTLE,
}
