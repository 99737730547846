import { Scene } from '@babylonjs/core';

import { LessonScene } from 'features/lesson-page/scene-components/init';

import { BaseObject } from '../../common/base';

import { InfoPanelObject } from '../info-panel';
import { ResultPanelObject, ResultPanelStore } from '../result-panel';

import { AskIfAcceptPanelObject } from '../ask-if-accept-panel';
import { SceneConfig } from './types';
import { SceneStore, SceneStoreList } from './store';
import { setupLogicForSceneResultPanel } from './logic';

type SceneObjectList = {
  askIfAcceptPanel1?: AskIfAcceptPanelObject;
  askIfAcceptPanel2?: AskIfAcceptPanelObject;
  infoPanel?: InfoPanelObject;
  resultPanel?: ResultPanelObject;
};

export class SceneObject extends BaseObject<SceneStore, SceneConfig> {
  private _objects: SceneObjectList;

  public get infoPanel(): InfoPanelObject | undefined {
    return this._objects.infoPanel;
  }

  public get resultPanel(): ResultPanelObject | undefined {
    return this._objects.resultPanel;
  }

  constructor(
    scene: Scene,
    objects: SceneObjectList,
    store: SceneStore,
    cfg: SceneConfig
  ) {
    super(scene, store, cfg);
    objects.askIfAcceptPanel1 && this.registerObject(objects.askIfAcceptPanel1);
    objects.askIfAcceptPanel2 && this.registerObject(objects.askIfAcceptPanel2);
    objects.infoPanel && this.registerObject(objects.infoPanel);
    this._objects = objects;
  }

  public static async setup(
    scene: Scene,
    cfg: SceneConfig
  ): Promise<SceneObject> {
    const none = Promise.resolve(undefined);

    const loadedObjects = Promise.all([
      cfg.askIfAcceptPanel1
        ? AskIfAcceptPanelObject.setup(scene, cfg.askIfAcceptPanel1)
        : none,
      cfg.askIfAcceptPanel2
        ? AskIfAcceptPanelObject.setup(scene, cfg.askIfAcceptPanel2)
        : none,
      cfg.infoPanel ? InfoPanelObject.setup(scene, cfg.infoPanel) : none,
      cfg.resultPanel ? ResultPanelObject.setup(scene, cfg.resultPanel) : none,
    ]);

    // Build objects
    const objects: SceneObjectList = {};
    [
      objects.askIfAcceptPanel1,
      objects.askIfAcceptPanel2,
      objects.infoPanel,
      objects.resultPanel,
    ] = await loadedObjects;

    // Build stores
    const stores: SceneStoreList = {};
    stores.askIfAcceptPanel1 = objects.askIfAcceptPanel1?.store;
    stores.askIfAcceptPanel2 = objects.askIfAcceptPanel2?.store;
    stores.infoPanel = objects.infoPanel?.store;

    // for legacy support
    stores.resultPanel = objects.resultPanel
      ? new ResultPanelStore()
      : undefined;

    return new SceneObject(scene, objects, new SceneStore(stores), cfg);
  }

  // TODO: исключительно для поддержания legacy кода
  public setupLegacyLogic(_scene: LessonScene): void {
    const scene = _scene.objects?.raw;
    if (!scene) return;

    scene.scene?.resultPanel?.connectToStore(this.store.resultPanel!);
    scene.scene?.resultPanel && setupLogicForSceneResultPanel(_scene);
  }
}
