import { autorun } from 'mobx';

import { Scene } from '@babylonjs/core';

import { BaseModelObject } from '../../common/base';
import { IntersectionController } from '../../common/intersection';

import { KettleFilterConfig } from './types';
import { KettleFilterModel } from './model';
import { KettleFilterStore } from './store';
import { setupLogic } from './logic';

export class KettleFilterObject extends BaseModelObject<
  KettleFilterModel,
  KettleFilterStore,
  KettleFilterConfig
> {
  private _intersect: IntersectionController;

  constructor(
    scene: Scene,
    model: KettleFilterModel,
    store: KettleFilterStore,
    cfg: KettleFilterConfig
  ) {
    super(scene, model, store, cfg);
    this._setLogicFunc(setupLogic);

    this._intersect = new IntersectionController(scene, store.intersect);
    this.registerController(this._intersect);
  }

  public static async setup(
    scene: Scene,
    cfg: KettleFilterConfig
  ): Promise<KettleFilterObject> {
    const model = await KettleFilterModel.load(cfg, scene);
    const store = new KettleFilterStore(model);
    return new KettleFilterObject(scene, model, store, cfg);
  }

  protected _connectToStore(
    store: KettleFilterStore,
    cfg: KettleFilterConfig
  ): void {
    autorun(() => {
      this.model.setVisibility(store.isVisible);
    });
  }
}
