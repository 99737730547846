import { Scene } from '@babylonjs/core';

import { BaseObject } from '../../common/base';
import { CoreConfig } from './types';
import { CoreStore, CoreStoreList } from './store';
import { XRObject } from '../xr';

type CoreObjectList = {
  xr?: XRObject;
};

export class CoreObject extends BaseObject<CoreStore, CoreConfig> {
  constructor(
    scene: Scene,
    objects: CoreObjectList,
    store: CoreStore,
    cfg: CoreConfig
  ) {
    super(scene, store, cfg);
    objects.xr && this.registerObject(objects.xr);
  }

  public static async setup(
    scene: Scene,
    cfg: CoreConfig
  ): Promise<CoreObject> {
    const none = Promise.resolve(undefined);

    const loadedObjects = Promise.all([
      cfg.xr ? XRObject.setup(scene, cfg.xr) : none,
    ]);

    // Build objects
    const objects: CoreObjectList = {};
    [objects.xr] = await loadedObjects;

    // Build stores
    const stores: CoreStoreList = {};
    stores.xr = objects.xr?.store;

    return new CoreObject(scene, objects, new CoreStore(stores), cfg);
  }
}
