import {
  AbstractMesh,
  ISceneLoaderAsyncResult,
  Scene,
  TransformNode,
} from '@babylonjs/core';

import { findByName, loadMesh } from '../../common/utils';

import { LandscapeConfig } from './types';

export interface ILandscapeModel {
  /**
   * Основной хелпер, к которому привязаны остальные элементы сцены
   */
  get mainHelper(): TransformNode;

  /**
   * Мешь, определяющий пол
   */
  get collisionFloor(): AbstractMesh;
}

export class LandscapeModel implements ILandscapeModel {
  public static readonly MODEL_VERSION = 'v0001';

  private _allMeshes: AbstractMesh[];
  private _root: TransformNode;
  private _collisionFloor: AbstractMesh;

  constructor(scene: Scene, model: ISceneLoaderAsyncResult) {
    this._allMeshes = model.meshes;
    [this._root] = model.meshes;
    this._collisionFloor = findByName(model.meshes, 'Landscape_collision');

    this.setVisibility(true);
  }

  static async load(
    scene: Scene,
    cfg: LandscapeConfig
  ): Promise<LandscapeModel> {
    const res = await loadMesh(scene, cfg.model, this.MODEL_VERSION);
    return new LandscapeModel(scene, res);
  }

  /**
   * Управлять глобальной видимостью объекта
   */
  public setVisibility(isVisible: boolean): void {
    for (const m of this._allMeshes) m.isVisible = isVisible;
    this._collisionFloor.isVisible = false;
  }

  /**
   * Установить есть ли проверка пересечений или нет
   */
  public setCollisionEnabled(isEnabled: boolean): void {
    this._collisionFloor.checkCollisions = isEnabled;
  }

  /**
   * Получить корень, за который можно привязывать модель
   */
  public get root(): TransformNode {
    return this._root;
  }

  public get mainHelper(): TransformNode {
    return this._root;
  }

  public get collisionFloor(): AbstractMesh {
    return this._collisionFloor;
  }
}
