import {
  AbstractMesh,
  AnimationGroup,
  ISceneLoaderAsyncResult,
  Mesh,
  Scene,
  TransformNode,
} from '@babylonjs/core';

import { findByName, loadMesh } from '../../common/utils';

import { KettleConfig } from './types';

export interface IKettleModel {
  /**
   * bbox снизу чайника для проверки пересечения с подставкой/столом
   */
  get baseBbox(): AbstractMesh;

  /**
   * bbox для проверки пересечения со струей воды
   */
  get waterJetBbox(): AbstractMesh;

  /**
   * bbox для проверки пересечения фильтра с чайником. Привязан к чайнику
   */
  get filterBbox(): AbstractMesh;

  /**
   * helper для прикрепления фильтра
   */
  get filterHelper(): TransformNode;
}

export class KettleModel implements IKettleModel {
  public static readonly MODEL_VERSION = 'v0006';

  private _root: TransformNode;

  private _baseBbox: AbstractMesh;
  private _handleBbox: AbstractMesh;
  private _visualCheckBbox: Mesh[];
  private _bottomLabelBbox: Mesh;
  private _powerButtonBbox: AbstractMesh;
  private _waterJetBbox: AbstractMesh;
  private _filterBbox: AbstractMesh;
  private _capBbox: AbstractMesh;
  private _capButtonBbox: AbstractMesh;
  private _waterLine: AbstractMesh;
  private _filterHelper: TransformNode;

  private _allMeshes: AbstractMesh[];
  private _allBboxes: AbstractMesh[];

  private _powerButtonAnim: AnimationGroup;
  private _capOpenAnim: AnimationGroup;
  private _capButtonPressAnim: AnimationGroup;

  /**
   * Корень, за который можно привязывать модель
   */
  public get root(): TransformNode {
    return this._root;
  }

  public get baseBbox(): AbstractMesh {
    return this._baseBbox;
  }

  public get waterJetBbox(): AbstractMesh {
    return this._waterJetBbox;
  }

  public get waterLine(): AbstractMesh {
    return this._waterLine;
  }

  /**
   * bbox ручки чайника
   */
  public get handleBbox(): AbstractMesh {
    return this._handleBbox;
  }

  /**
   * bbox надписи на чайнике снизу
   */
  public get bottomLabelBbox(): Mesh {
    return this._bottomLabelBbox;
  }

  /**
   * bbox кнопки включения чайника
   */
  public get powerButtonBbox(): AbstractMesh {
    return this._powerButtonBbox;
  }

  public get filterBbox(): AbstractMesh {
    return this._filterBbox;
  }

  /**
   * bbox крышки чайника. Используется для закрытия крышки
   */
  public get capBbox(): AbstractMesh {
    return this._capBbox;
  }

  /**
   * bbox кнопки открытия крышки на чайнике. Используется для открытия крышки
   */
  public get capButtonBbox(): AbstractMesh {
    return this._capButtonBbox;
  }

  /**
   * Набор bbox'ов для визуальной проверки чайника
   */
  public get visualCheckBbox(): Mesh[] {
    return this._visualCheckBbox;
  }

  public get filterHelper(): TransformNode {
    return this._filterHelper;
  }

  constructor(scene: Scene, model: ISceneLoaderAsyncResult) {
    this._root = findByName(model.transformNodes, 'Kettle_Helper');
    this._root.position.y = 1.3;
    this._baseBbox = findByName(model.meshes, 'Bbox_StandCrossing');
    this._handleBbox = findByName(model.meshes, 'Bbox_Handle');
    this._bottomLabelBbox = findByName(model.meshes, 'Bbox_Label') as Mesh;
    this._powerButtonBbox = findByName(model.meshes, 'Bbox_PowerButton');
    this._waterJetBbox = findByName(model.meshes, 'Bbox_TeapotBottom');
    this._filterBbox = findByName(model.meshes, 'Bbox_Filter_StandardPosition');
    this._capBbox = findByName(model.meshes, 'Bbox_Cap');
    this._capButtonBbox = findByName(model.meshes, 'Bbox_OpeningButton');
    this._waterLine = findByName(model.meshes, 'WaterLine');
    this._visualCheckBbox = model.meshes
      .filter((m) => m.name.startsWith('Bbox_VisualInspection'))
      .map((m) => m as Mesh);
    if (this._visualCheckBbox.length === 0)
      throw Error('Visual check bboxes (visualCheckBbox) not found');

    this._allBboxes = [
      this._baseBbox,
      this._handleBbox,
      this._powerButtonBbox,
      this._waterJetBbox,
      this._filterBbox,
      this._capBbox,
      this._capButtonBbox,
      // ...this._visualCheckBbox,
    ];
    this._allMeshes = model.meshes.filter((m) => {
      return !this._allBboxes.includes(m);
    });

    this._filterHelper = findByName(model.transformNodes, 'Filter_Helper');

    this._powerButtonAnim = findByName(model.animationGroups, 'PowerButton_ON');
    this._capOpenAnim = findByName(model.animationGroups, 'CapOpen');
    this._capButtonPressAnim = findByName(
      model.animationGroups,
      'OpeningButton_Pressed'
    );

    this.setVisibility(false);
  }

  static async load(cfg: KettleConfig, scene: Scene): Promise<KettleModel> {
    const res = await loadMesh(scene, cfg.model, this.MODEL_VERSION);
    return new KettleModel(scene, res);
  }

  public setVisibility(isVisible: boolean): void {
    for (const m of this._allMeshes) m.isVisible = isVisible;
    for (const m of this._allBboxes) m.isVisible = false;
  }

  public setWaterLineVisibility(isVisible: boolean): void {
    this.waterLine.isVisible = isVisible;
  }

  public setPowerButtonState(isEnable: boolean): void {
    const anim = this._powerButtonAnim;
    if (isEnable) anim.start(false, 1.0, anim.from, anim.to);
    else anim.start(false, 1.0, anim.to, anim.from);
  }

  public setCapOpenState(isOpen: boolean): void {
    const anim = this._capOpenAnim;
    if (isOpen) anim.start(false, 1.0, anim.from, anim.to);
    else anim.start(false, 1.0, anim.to, anim.from);
  }

  public pressCapButton(): void {
    const anim = this._capButtonPressAnim;
    anim.start(false, 1.0, anim.from, anim.to);
  }
}
