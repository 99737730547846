import { Scene } from '@babylonjs/core';
import { autorun } from 'mobx';

import { BaseModelObject } from '../../common/base';

import { AskIfAcceptPanelConfig } from './types';
import { AskIfAcceptPanelStore } from './store';
import { AskIfAcceptWindowModel } from '../../common/user-interfaces/ask-if-accept-window/model';
import { AskIfAcceptWindowObject } from '../../common/user-interfaces/ask-if-accept-window/object';
import { setupLogic } from './logic';

export class AskIfAcceptPanelObject extends BaseModelObject<
  AskIfAcceptWindowModel,
  AskIfAcceptPanelStore,
  AskIfAcceptPanelConfig
> {
  private _window: AskIfAcceptWindowObject;

  constructor(
    scene: Scene,
    model: AskIfAcceptWindowModel,
    store: AskIfAcceptPanelStore,
    cfg: AskIfAcceptPanelConfig
  ) {
    super(scene, model, store, cfg);
    this._setLogicFunc(setupLogic);

    this._window = new AskIfAcceptWindowObject(scene, model, store, cfg.window);
    this.registerObject(this._window);
  }

  public static async setup(
    scene: Scene,
    cfg: AskIfAcceptPanelConfig
  ): Promise<AskIfAcceptPanelObject> {
    const model = await AskIfAcceptWindowModel.load(scene, cfg.window);
    const store = new AskIfAcceptPanelStore(model);
    return new AskIfAcceptPanelObject(scene, model, store, cfg);
  }

  protected _connectToStore(
    store: AskIfAcceptPanelStore,
    cfg: AskIfAcceptPanelConfig
  ): void {
    // Перенаправление callback'ов
    this._window.gui.onAcceptButtonClickCallback.add(() => {
      store.setAnswer(true);
    });
    this._window.gui.onRejectButtonClickCallback.add(() => {
      store.setAnswer(false);
    });

    autorun(() => {
      const { parent } = store;
      if (parent === undefined) return;

      const { root } = this.model;
      root.parent = parent;

      const [pos, rot] = [cfg.position, cfg.rotation];
      root.position.set(pos.x, pos.y, pos.z);
      root.rotation.set(rot.x, rot.y, rot.z);
    });
  }
}
