import {
  AbstractMesh,
  AnimationGroup,
  ISceneLoaderAsyncResult,
  Mesh,
  Scene,
  TransformNode,
} from '@babylonjs/core';

import { findByName, loadMesh } from '../../common/utils';

import { KitchenAllConfig, WaterJetState } from './types';

export interface IKitchenAllModel {
  /**
   * bbox вокруг струи воды
   */
  get waterJetBbox(): AbstractMesh;

  /**
   * bbox правой столешницы
   */
  get rightCountertopBbox(): AbstractMesh;

  /**
   * bbox ручки крана
   */
  get tapHandleBbox(): AbstractMesh;
}

export class KitchenAllModel implements IKitchenAllModel {
  public static readonly MODEL_VERSION = 'v0002';

  private _root: TransformNode;
  private _allMeshes: AbstractMesh[];
  private _waterJetBbox: AbstractMesh;
  private _rightCountertopBbox: AbstractMesh;
  private _tapHandleBbox: AbstractMesh;
  private _visualCheckBbox: Mesh[];

  private _tapOpenAnim: AnimationGroup;
  private _waterJetAnim: AnimationGroup;

  public get root(): TransformNode {
    return this._root;
  }

  public get waterJetBbox(): AbstractMesh {
    return this._waterJetBbox;
  }

  public get rightCountertopBbox(): AbstractMesh {
    return this._rightCountertopBbox;
  }

  public get tapHandleBbox(): AbstractMesh {
    return this._tapHandleBbox;
  }

  /**
   * Набор bbox'ов для визуальной электро щита
   */
  public get visualCheckBbox(): Mesh[] {
    return this._visualCheckBbox;
  }

  constructor(scene: Scene, model: ISceneLoaderAsyncResult) {
    this._allMeshes = model.meshes;
    this._root = findByName(model.transformNodes, 'KitchenAll');
    this._waterJetBbox = findByName(model.meshes, 'Bbox_WaterJetCrossing');
    this._rightCountertopBbox = findByName(model.meshes, 'Bbox_TeapotCrossing');
    this._tapHandleBbox = findByName(model.meshes, 'Bbox_CraneHandle');
    this._visualCheckBbox = [];
    this._visualCheckBbox.push(
      findByName(model.meshes, 'Bbox_ElectricalPanel') as Mesh
    );

    this._tapOpenAnim = findByName(model.animationGroups, 'CraneHandle_ON');
    this._waterJetAnim = findByName(model.animationGroups, 'WaterJet_Action');

    this.setVisibility(false);
  }

  static async load(
    cfg: KitchenAllConfig,
    scene: Scene
  ): Promise<KitchenAllModel> {
    const res = await loadMesh(scene, cfg.model, this.MODEL_VERSION);
    return new KitchenAllModel(scene, res);
  }

  public setVisibility(isVisible: boolean): void {
    for (const m of this._allMeshes) m.isVisible = isVisible;
    this._waterJetBbox.isVisible = false;
    this._rightCountertopBbox.isVisible = false;
    this._tapHandleBbox.isVisible = false;
  }

  public setWaterJetStatus(status: WaterJetState): void {
    const target = {
      [WaterJetState.CLOSED]: 0,
      [WaterJetState.HALF_JET]: 0.07,
      [WaterJetState.FULL_JET]: 0.125,
    }[status];
    const anim = this._waterJetAnim;
    anim.start(false, 1.0, target, target);
  }

  public setTapIsOpen(isOpen: boolean): void {
    const anim = this._tapOpenAnim;
    if (isOpen) anim.start(false, 1.0, anim.from, anim.to);
    else anim.start(false, 1.0, anim.to, anim.from);
  }
}
