import { action, makeAutoObservable } from 'mobx';

import { IntersectionTarget } from './types';

export class IntersectionStore {
  private _isEnabled = true;
  private _targets1: IntersectionTarget[] = [];
  private _targets2: IntersectionTarget[] = [];
  private _marks: [number, number][] = [];

  public get isEnabled(): boolean {
    return this._isEnabled;
  }

  public get targets1(): IntersectionTarget[] {
    return this._targets1;
  }

  public get targets2(): IntersectionTarget[] {
    return this._targets2;
  }

  public get marks(): [number, number][] {
    return this._marks;
  }

  constructor() {
    makeAutoObservable(this, {
      setEnabled: action,
      setTargets1: action,
      setTargets2: action,
      markTarget: action,
      resetMarks: action,
    });
  }

  public setEnabled(isEnabled: boolean): void {
    this._isEnabled = isEnabled;
  }

  public setTargets1(targets: IntersectionTarget[]): void {
    this._targets1 = targets;
  }

  public setTargets2(targets: IntersectionTarget[]): void {
    this._targets2 = targets;
  }

  public markTarget(idx: [number, number], mark: boolean): void {
    if (!this._isEnabled) return;
    if (mark) {
      if (this._marks.some((e) => e[0] === idx[0] && e[1] === idx[1])) return;
      this._marks.push(idx);
    } else {
      for (let i = 0; i < this._marks.length; i += 1) {
        const m = this._marks[i];
        if (m[0] === idx[0] && m[1] === idx[1]) {
          this._marks.splice(i, 1);
          i -= 1;
        }
      }
    }
  }

  public resetMarks(): void {
    this._marks.splice(0);
  }

  public has(
    target1: number | undefined,
    target2: number | undefined
  ): boolean {
    for (const [id1, id2] of this.marks) {
      if (
        (target1 === undefined || target1 === id1) &&
        (target2 === undefined || target2 === id2)
      )
        return true;
    }
    return false;
  }
}
