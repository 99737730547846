import { action, makeAutoObservable } from 'mobx';

import { IntersectionStore } from '../intersection/store';
import { IntersectionVisualCheckModel } from './model';

export class IntersectionVisualCheckStore {
  private _model: IntersectionVisualCheckModel;
  private _isEnabled = false;
  private _marks: Set<number> = new Set();
  private _intersect = new IntersectionStore();

  public get model(): IntersectionVisualCheckModel {
    return this._model;
  }

  public get isEnabled(): boolean {
    return this._isEnabled;
  }

  public get allMarked(): boolean {
    return this._marks.size >= this._model.meshes.length;
  }

  public get marks(): Set<number> {
    return this._marks;
  }

  public get intersect(): IntersectionStore {
    return this._intersect;
  }

  constructor(model: IntersectionVisualCheckModel) {
    this._model = model;
    makeAutoObservable(this, {
      setEnabled: action,
      addMark: action,
      reset: action,
    });
  }

  public setEnabled(isEnabled: boolean): void {
    this._isEnabled = isEnabled;
  }

  public addMark(mark: number): void {
    this._marks.add(mark);
  }

  public reset(): void {
    this._marks.clear();
  }
}
