import { Scene } from '@babylonjs/core';

import { BaseController, BaseObject } from '../../common/base';

import { KitchenAllObject } from '../kitchen-all';

import { FurnitureConfig } from './types';
import { FurnitureStore, FurnitureStoreList } from './store';

import { FurnitureType } from '../types';

type ObjectClassesList = {
  [key in FurnitureType]: {
    setup: (
      scene: Scene,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cfg: any
    ) => Promise<BaseController<unknown, unknown>>;
  };
};

const objectClasses: ObjectClassesList = {
  kitchenAll: KitchenAllObject,
};

type FurnitureObjectList = {
  [key in FurnitureType]: BaseController<unknown, unknown> | undefined;
};

export class FurnitureObject extends BaseObject<
  FurnitureStore,
  FurnitureConfig
> {
  constructor(
    scene: Scene,
    objects: FurnitureObjectList,
    store: FurnitureStore,
    cfg: FurnitureConfig
  ) {
    super(scene, store, cfg);
    for (const obj of Object.values(objects)) {
      obj && this.registerObject(obj);
    }
  }

  public static async setup(
    scene: Scene,
    cfg: FurnitureConfig
  ): Promise<FurnitureObject> {
    const objectKeys = Object.values(FurnitureType);

    const objectsToLoad = [];
    for (const k of objectKeys) {
      objectsToLoad.push(
        cfg[k]
          ? objectClasses[k].setup(scene, cfg[k])
          : Promise.resolve(undefined)
      );
    }
    const loadedObjects = await Promise.all(objectsToLoad);

    // Build objects and stores
    const objects: FurnitureObjectList = {} as FurnitureObjectList;
    const stores: FurnitureStoreList = {} as FurnitureStoreList;

    for (let i = 0; i < objectKeys.length; i += 1) {
      const k = objectKeys[i];
      objects[k] = loadedObjects[i];
      stores[k] = loadedObjects[i]?.store;
    }

    return new FurnitureObject(scene, objects, new FurnitureStore(stores), cfg);
  }
}
