import { action, makeAutoObservable } from 'mobx';

import { Mesh, TransformNode, Vector3 } from '@babylonjs/core';

import { IntersectionStore } from '../../common/intersection';

import { HandType } from '../../player/hands';
import { IInventoryWantInHandStore } from '../../player/inventory';
import { IKettleModel, KettleModel } from './model';
import { IntersectionVisualCheckStore } from '../../common/intersection-visual-check';
import { KettlePlace } from './types';

export interface IKettleStore {
  /**
   * Модель
   */
  get model(): IKettleModel;

  /**
   * Находится ли чайник находится в руке. Если да, то в какой
   */
  get isInHand(): HandType | undefined;

  /**
   * Находится ли чайник на базе
   */
  get isOnBase(): boolean;

  /**
   * Находится ли чайник на столе
   */
  get isOnTable(): boolean;

  /**
   * Открыта ли крышка чайника
   */
  get isCapOpen(): boolean;

  /**
   * Виден ли предмет
   */
  get isVisible(): boolean;

  /**
   * Наполнен ли чайник
   */
  get isFilled(): boolean;

  /**
   * Все ли визуальные элементы проверены
   */
  get areAllChecked(): boolean;
}

export class KettleStore implements IKettleStore, IInventoryWantInHandStore {
  private _model: KettleModel;
  private _visible = false;
  private _place: KettlePlace = KettlePlace.UNKNOWN;
  private _wantPlace: KettlePlace = KettlePlace.UNKNOWN;
  private _isCapOpen = false;
  private _isFilled = false;
  private _intersectBase = new IntersectionStore();
  private _intersectHands = new IntersectionStore();
  private _visualCheck: IntersectionVisualCheckStore;
  private _labelVisualCheck: IntersectionVisualCheckStore;

  public get model(): KettleModel {
    return this._model;
  }

  public get isFilled(): boolean {
    return this._isFilled;
  }

  public get intersectBase(): IntersectionStore {
    return this._intersectBase;
  }

  public get intersectHands(): IntersectionStore {
    return this._intersectHands;
  }

  public get visualCheck(): IntersectionVisualCheckStore {
    return this._visualCheck;
  }

  public get areAllChecked(): boolean {
    return this._visualCheck.allMarked;
  }

  public get labelVisualCheck(): IntersectionVisualCheckStore {
    return this._labelVisualCheck;
  }

  public get areLabelChecked(): boolean {
    return this._labelVisualCheck.allMarked;
  }

  public get wantPlace(): KettlePlace {
    return this._wantPlace;
  }

  public get wantInHand(): HandType | undefined {
    if (this._wantPlace === KettlePlace.IN_MAIN_HAND) return HandType.MAIN;
    if (this._wantPlace === KettlePlace.IN_MINOR_HAND) return HandType.MINOR;
    return undefined;
  }

  public get isInHand(): HandType | undefined {
    if (this._place === KettlePlace.IN_MAIN_HAND) return HandType.MAIN;
    if (this._place === KettlePlace.IN_MINOR_HAND) return HandType.MINOR;
    return undefined;
  }

  public get isOnBase(): boolean {
    return this._place === KettlePlace.ON_BASE;
  }

  public get isOnTable(): boolean {
    return this._place === KettlePlace.ON_TABLE;
  }

  public get isCapOpen(): boolean {
    return this._isCapOpen;
  }

  public get isVisible(): boolean {
    return this._visible;
  }

  constructor(model: KettleModel) {
    this._model = model;
    this._visualCheck = new IntersectionVisualCheckStore({
      meshes: this._model.visualCheckBbox,
    });
    this._labelVisualCheck = new IntersectionVisualCheckStore({
      meshes: [this._model.bottomLabelBbox],
    });
    makeAutoObservable(this, {
      setVisibility: action,
      setWantPlace: action,
      setIsCapOpen: action,
      moveToHand: action,
      moveToKettleBase: action,
    });
  }

  /**
   * Установить виден ли предмет
   */
  public setVisibility(isVisible: boolean): void {
    this._visible = isVisible;
  }

  /**
   * Установить заполнен ли чайник
   */
  public setIsFilled(isFilled: boolean): void {
    this._isFilled = isFilled;
  }

  /**
   * Установить хочет ли объект быть в руках
   */
  public setWantPlace(wantPlace: KettlePlace): void {
    this._wantPlace = wantPlace;
  }

  /**
   * Установить открыта ли крышка
   */
  public setIsCapOpen(isCapOpen: boolean): void {
    this._isCapOpen = isCapOpen;
  }

  /**
   * Переместить чайник в руку
   */
  public moveToHand(parent: TransformNode | null, hand: HandType): void {
    this._place =
      hand === HandType.MAIN
        ? KettlePlace.IN_MAIN_HAND
        : KettlePlace.IN_MINOR_HAND;
    this._model.root.parent = parent;
    this._model.root.position = new Vector3(0, 0, 0);
    this._model.root.rotation = new Vector3(0, 0, 0);
  }

  /**
   * Переместить чайник на подставку
   */
  public moveToKettleBase(parent: TransformNode | null): void {
    this._place = KettlePlace.ON_BASE;
    this._model.root.parent = parent;
    this._model.root.position = new Vector3(0, 0, 0);
  }

  /**
   * Переместить чайник на стол
   */
  public moveToTable(parent: TransformNode | null): void {
    this._place = KettlePlace.ON_TABLE;
    this._model.root.setParent(parent);
    this._model.root.position.y = 0;
    this._model.root.rotation.x = 0;
    this._model.root.rotation.z = 0;
  }
}
