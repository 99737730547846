import { autorun } from 'mobx';

import { Vector3 } from '@babylonjs/core';

import { LessonStore } from 'features/lesson-page/store';

import { HandState, HandsConfig } from './types';
import { HandsStore } from './store';
import { IInventoryItem } from '../inventory';
import { KitchenwareType } from '../../kitchenware/types';
import { ObjectsType } from '../../types';

function getHandsState(item: IInventoryItem): HandState {
  if (item.group === ObjectsType.KITCHENWARE) {
    switch (item.toKitchenware) {
      case KitchenwareType.KETTLE:
        return HandState.KETTLE_TAKE;
      case KitchenwareType.KETTLE_BASE:
        return HandState.STAND_TAKE;
      case KitchenwareType.KETTLE_FILTER:
        return HandState.KETTLE_FILTER_TAKE;
      default:
        return HandState.FIXED;
    }
  }
  return HandState.FIXED;
}

export function setupLogic(
  store: LessonStore,
  objStore: HandsStore,
  cfg: HandsConfig
): void {
  const stCore = store.objects.core;
  const stPlayer = store.objects.player;
  if (!stCore) throw Error('Cannot configure without objects.core');
  if (!stPlayer) throw Error('Cannot configure without objects.player');

  const { inventory } = stPlayer;
  if (!inventory) throw Error('Cannot configure without player.inventory');

  autorun(() => {
    if (!store.isVR) {
      objStore.setVisibility(false);
      return;
    }

    const cLeft = stCore.xr?.controllers.left;
    objStore.left.setVisibility(cLeft !== undefined);
    if (cLeft) {
      objStore.left.model.root.parent = cLeft.pointer;
      objStore.left.model.root.position = new Vector3(0, 0, 0);
    }

    const cRight = stCore.xr?.controllers.right;
    objStore.right.setVisibility(cRight !== undefined);
    if (cRight) {
      objStore.right.model.root.parent = cRight.pointer;
      objStore.right.model.root.position = new Vector3(0, 0, 0);
    }
  });

  // Переключение главной руки
  let lastAState: boolean | undefined;
  autorun(() => {
    if (!stCore.xr) return;
    const { aDown } = stCore.xr?.controllers.buttons;
    if (aDown === lastAState) return;
    lastAState = aDown;

    if (aDown) objStore.swapMainHand();
  });

  autorun(() => {
    const mainState = getHandsState(inventory.mainHandItem);
    const minorState = getHandsState(inventory.minorHandItem);
    objStore.mainHand.setTargetState(mainState);
    objStore.minorHand.setTargetState(minorState);
  });

  autorun(() => {
    if (!stPlayer.inventory) return;
    const isGloveSelected = stPlayer.inventory?.gloveSelected;
    objStore.setGloveVisibility(isGloveSelected);
  });
}
