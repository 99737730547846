import { action, makeAutoObservable } from 'mobx';

import { TransformNode, Vector3 } from '@babylonjs/core';

import { IntersectionStore } from '../../common/intersection';

import { HandType } from '../../player/hands';
import { IInventoryWantInHandStore } from '../../player/inventory';
import { IKettleFilterModel, KettleFilterModel } from './model';
import { KettleFilterPlace } from './types';

export interface IKettleFilterStore {
  /**
   * Модель
   */
  get model(): IKettleFilterModel;

  /**
   * Находится ли фильтр в руке. Если да, то в какой
   */
  get isInHand(): HandType | undefined;

  /**
   * Находится ли фильтр в чайнике
   */
  get isInKettle(): boolean;

  /**
   * Виден ли предмет
   */
  get isVisible(): boolean;
}

export class KettleFilterStore
  implements IKettleFilterStore, IInventoryWantInHandStore
{
  private _model: KettleFilterModel;
  private _visible = false;
  private _place: KettleFilterPlace = KettleFilterPlace.UNKNOWN;
  private _wantPlace: KettleFilterPlace = KettleFilterPlace.UNKNOWN;
  private _canBeTakenInHand = true;

  private _intersect = new IntersectionStore();

  public get model(): KettleFilterModel {
    return this._model;
  }

  public get intersect(): IntersectionStore {
    return this._intersect;
  }

  public get wantPlace(): KettleFilterPlace {
    return this._wantPlace;
  }

  public get wantInHand(): HandType | undefined {
    if (this._wantPlace === KettleFilterPlace.IN_MAIN_HAND)
      return HandType.MAIN;
    if (this._wantPlace === KettleFilterPlace.IN_MINOR_HAND)
      return HandType.MINOR;
    return undefined;
  }

  public get isInHand(): HandType | undefined {
    if (this._place === KettleFilterPlace.IN_MAIN_HAND) return HandType.MAIN;
    if (this._place === KettleFilterPlace.IN_MINOR_HAND) return HandType.MINOR;
    return undefined;
  }

  public get isInKettle(): boolean {
    return this._place === KettleFilterPlace.IN_KETTLE;
  }

  /**
   * Может ли фильтр быть взять в руку
   */
  public get canBeTakenInHand(): boolean {
    return this._canBeTakenInHand;
  }

  public get isVisible(): boolean {
    return this._visible;
  }

  constructor(model: KettleFilterModel) {
    this._model = model;
    makeAutoObservable(this, {
      setVisibility: action,
      setCanBeTakenInHand: action,
      setWantPlace: action,
      moveToHand: action,
      moveToKettle: action,
    });
  }

  /**
   * Установить виден ли предмет
   */
  public setVisibility(isVisible: boolean): void {
    this._visible = isVisible;
  }

  /**
   * Установить может ли фильтр быть взять в руку
   */
  public setCanBeTakenInHand(canBeTaken: boolean): void {
    this._canBeTakenInHand = canBeTaken;
  }

  /**
   * Установить хочет ли объект быть в руках
   */
  public setWantPlace(wantPlace: KettleFilterPlace): void {
    this._wantPlace = wantPlace;
  }

  /**
   * Переместить фильтр в руку
   */
  public moveToHand(parent: TransformNode | null, hand: HandType): void {
    this._place =
      hand === HandType.MAIN
        ? KettleFilterPlace.IN_MAIN_HAND
        : KettleFilterPlace.IN_MINOR_HAND;
    this._model.root.parent = parent;
    this._model.root.position = new Vector3(0, 0, 0);
    this._model.root.rotation = new Vector3(0, 0, 0);
  }

  /**
   * Переместить фильтр в чайник
   */
  public moveToKettle(parent: TransformNode | null): void {
    this._place = KettleFilterPlace.IN_KETTLE;
    this._model.root.parent = parent;
    this._model.root.position = new Vector3(0, 0, 0);
    this._model.root.rotation = new Vector3(0, 0, 0);
  }
}
