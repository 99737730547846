import { autorun, reaction } from 'mobx';

import { LessonStore } from 'features/lesson-page/store';

import { IntersectionTarget } from '../../common/intersection';

import { HandType } from '../../player/hands';
import { KettleConfig, KettlePlace } from './types';
import { KettleStore } from './store';
import { KitchenwareType } from '../types';
import { ObjectsType } from '../../types';

enum IntersectionTargetId {
  KETTLE_CAP_BUTTON_BBOX,
  KETTLE_CAP_BBOX,
  KETTLE_HANDLE_BBOX,
  KETTLE_BASE_BBOX,
  KETTLEBASE_BBOX,
  KITCHEN_TABLE_RIGHT,
  WATER_JET_BBOX,
}

export function setupLogic(
  store: LessonStore,
  objStore: KettleStore,
  cfg: KettleConfig
): void {
  const stCore = store.objects.core;
  const stPlayer = store.objects.player;
  const stKitchenware = store.objects.kitchenware;
  const stKitchFurniture = store.objects.furniture;
  if (!stCore) throw Error('Cannot configure without objects.core');
  if (!stPlayer) throw Error('Cannot configure without objects.player');
  if (!stKitchenware)
    throw Error('Cannot configure without objects.kitchenware');
  if (!stKitchFurniture)
    throw Error('Cannot configure without objects.furniture');

  const { xr } = stCore;
  const { hands, inventory } = stPlayer;
  const { kettleBase } = stKitchenware;
  const { kitchenAll } = stKitchFurniture;
  if (!xr) throw Error('Cannot configure without objects.core.xr');
  if (!hands) throw Error('Cannot configure without objects.player.hands');
  if (!inventory)
    throw Error('Cannot configure without objects.player.inventory');
  if (!kettleBase)
    throw Error('Cannot configure without objects.kitchenware.kettleBase');
  if (!kitchenAll)
    throw Error('Cannot configure without objects.furniture.kitchenAll');
  objStore.setVisibility(true);

  inventory.registerObjectWantInHand(objStore, {
    group: ObjectsType.KITCHENWARE,
    object: KitchenwareType.KETTLE,
  });

  autorun(() => {
    objStore.visualCheck.setEnabled(objStore.isInHand !== undefined);
  });
  autorun(() => {
    objStore.labelVisualCheck.setEnabled(
      store.script?.currentStep?.doneCondition?.stateName === 'isCorrectPower'
    );
  });

  // Установка целевых объектов для intersectBase
  autorun(() => {
    objStore.intersectBase.setTargets1([
      {
        id: IntersectionTargetId.KETTLE_BASE_BBOX,
        mesh: objStore.model.baseBbox,
      },
    ]);

    const targets2: IntersectionTarget[] = [
      {
        id: IntersectionTargetId.KITCHEN_TABLE_RIGHT,
        mesh: kitchenAll.model.rightCountertopBbox,
      },
    ];
    targets2.push({
      id: IntersectionTargetId.WATER_JET_BBOX,
      mesh: stKitchFurniture.kitchenAll!.model.waterJetBbox,
    });
    if (kettleBase.isBusy === false && objStore.isInHand !== undefined)
      targets2.push({
        id: IntersectionTargetId.KETTLEBASE_BBOX,
        mesh: kettleBase.model.kettleBbox,
      });
    objStore.intersectBase.setTargets2(targets2);
  });

  // Установка целевых объектов для intersectHands
  autorun(() => {
    const targets1: IntersectionTarget[] = [];
    if (inventory.mainHandItem.isEmpty && hands.mainHand.isVisible)
      targets1.push({ id: HandType.MAIN, mesh: hands.mainHand.model.bbox });
    if (inventory.minorHandItem.isEmpty && hands.minorHand.isVisible)
      targets1.push({ id: HandType.MINOR, mesh: hands.minorHand.model.bbox });
    objStore.intersectHands.setTargets1(targets1);

    const targets2: IntersectionTarget[] = [];
    if (objStore.isInHand === undefined)
      targets2.push({
        id: IntersectionTargetId.KETTLE_HANDLE_BBOX,
        mesh: objStore.model.handleBbox,
      });
    if (objStore.isCapOpen)
      targets2.push({
        id: IntersectionTargetId.KETTLE_CAP_BBOX,
        mesh: objStore.model.capBbox,
      });
    else
      targets2.push({
        id: IntersectionTargetId.KETTLE_CAP_BUTTON_BBOX,
        mesh: objStore.model.capButtonBbox,
      });
    objStore.intersectHands.setTargets2(targets2);
  });

  autorun(() => {
    objStore.intersectHands.setEnabled(objStore.isVisible);
    objStore.intersectBase.setEnabled(objStore.isVisible);
  });

  // Изменение положения объекта в зависимости от его желания, состояния интерфейса и рук
  let handAnimationIsDone = false;
  autorun(() => {
    switch (objStore.wantPlace) {
      case KettlePlace.ON_BASE:
        objStore.moveToKettleBase(kettleBase.model.kettleHelper);
        break;
      case KettlePlace.ON_TABLE:
        objStore.moveToTable(kitchenAll.model.rightCountertopBbox);
        break;
      case KettlePlace.IN_MAIN_HAND:
      case KettlePlace.IN_MINOR_HAND: {
        const isInHand = inventory.find(
          ObjectsType.KITCHENWARE,
          KitchenwareType.KETTLE
        );
        if (isInHand === undefined) break;

        const hand = hands.getHand(isInHand);
        if (hand.animating) {
          handAnimationIsDone =
            handAnimationIsDone || objStore.isInHand !== isInHand;
          break;
        }

        if (handAnimationIsDone) {
          handAnimationIsDone = false;
          objStore.moveToHand(hand.model.helperTake, hand.type);
        }
      }
    }
  });
  autorun(() => {
    // Проверка есть ли пересечение чайника c водой
    if (
      objStore.intersectBase.isEnabled &&
      stKitchFurniture.kitchenAll?.isTapOpen &&
      objStore.isCapOpen &&
      objStore.intersectBase.has(
        IntersectionTargetId.KETTLE_BASE_BBOX,
        IntersectionTargetId.WATER_JET_BBOX
      )
    ) {
      objStore.setIsFilled(true);
    }
  });


  const onStandardTriggerDown = (isLeft: boolean): void => {
    const hand = isLeft ? hands.left : hands.right;
    // Проверка есть ли пересечение чайника с подставкой
    if (
      hand.type === objStore.isInHand &&
      objStore.intersectBase.isEnabled &&
      objStore.intersectBase.has(
        IntersectionTargetId.KETTLE_BASE_BBOX,
        IntersectionTargetId.KETTLEBASE_BBOX
      )
    ) {
      objStore.setWantPlace(KettlePlace.ON_BASE);
      return;
    }

    // Проверка есть ли пересечение чайника co столом
    if (
      hand.type === objStore.isInHand &&
      objStore.intersectBase.isEnabled &&
      objStore.intersectBase.has(
        IntersectionTargetId.KETTLE_BASE_BBOX,
        IntersectionTargetId.KITCHEN_TABLE_RIGHT
      )
    ) {
      objStore.setWantPlace(KettlePlace.ON_TABLE);
      return;
    }

    // Проверка есть ли пересечение руки, на кнопку которой нажали, с ручкой чайника
    if (
      objStore.intersectHands.isEnabled &&
      objStore.intersectHands.has(
        hand.type,
        IntersectionTargetId.KETTLE_HANDLE_BBOX
      )
    ) {
      objStore.setWantPlace(
        hand.isMainHand ? KettlePlace.IN_MAIN_HAND : KettlePlace.IN_MINOR_HAND
      );
      return;
    }

    // Проверка есть ли пересечение руки, на кнопку которой нажали, с крышкой чайника
    if (
      objStore.intersectHands.isEnabled &&
      objStore.intersectHands.has(
        hand.type,
        IntersectionTargetId.KETTLE_CAP_BBOX
      )
    ) {
      objStore.setIsCapOpen(false);
      return;
    }

    // Проверка есть ли пересечение руки, на кнопку которой нажали, с кнопкой открытия крышки чайника
    if (
      objStore.intersectHands.isEnabled &&
      objStore.intersectHands.has(
        hand.type,
        IntersectionTargetId.KETTLE_CAP_BUTTON_BBOX
      )
    ) {
      objStore.setIsCapOpen(true);
    }
  };

  reaction(
    () => xr.controllers.left?.buttons.standardTriggerDown,
    (isDown) => isDown && onStandardTriggerDown(true)
  );
  reaction(
    () => xr.controllers.right?.buttons.standardTriggerDown,
    (isDown) => isDown && onStandardTriggerDown(false)
  );
}
