import { ILandscapeStore, LandscapeStore } from '../landscape';
import { ILightsStore, LightsStore } from '../lights';
import { ISkyboxStore, SkyboxStore } from '../skybox';

export type WorldStoreList = {
  landscape?: LandscapeStore;
  lights?: LightsStore;
  skybox?: SkyboxStore;
};

export interface IWorldStore {
  /**
   * Ланшафт
   */
  get landscape(): ILandscapeStore | undefined;

  /**
   * Свет
   */
  get lights(): ILightsStore | undefined;

  /**
   * Скайбокс
   */
  get skybox(): ISkyboxStore | undefined;
}

export class WorldStore implements IWorldStore {
  private _stores: WorldStoreList;

  public get landscape(): LandscapeStore | undefined {
    return this._stores.landscape;
  }

  public get lights(): LightsStore | undefined {
    return this._stores.lights;
  }

  public get skybox(): SkyboxStore | undefined {
    return this._stores.skybox;
  }

  constructor(stores: WorldStoreList) {
    this._stores = stores;
  }
}
