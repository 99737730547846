import { action, makeAutoObservable } from 'mobx';

import { TransformNode, Vector3 } from '@babylonjs/core';

import { IntersectionStore } from '../../common/intersection';

import { HandType } from '../../player/hands';
import { IInventoryWantInHandStore } from '../../player/inventory';
import { IKettleBaseModel, KettleBaseModel } from './model';
import { IntersectionVisualCheckStore } from '../../common/intersection-visual-check';
import { KettleBasePlace } from './types';

export interface IKettleBaseStore {
  /**
   * Модель
   */
  get model(): IKettleBaseModel;

  /**
   * Находится ли подставка в руке. Если да, то в какой
   */
  get isInHand(): HandType | undefined;

  /**
   * Занята ли подставка
   */
  get isBusy(): boolean;

  /**
   * Виден ли предмет
   */
  get isVisible(): boolean;

  /**
   * Находится ли подставка на столе
   */
  get isOnTable(): boolean;

  /**
   * Все ли визуальные элементы проверены
   */
  get areAllChecked(): boolean;
}

export class KettleBaseStore
  implements IKettleBaseStore, IInventoryWantInHandStore
{
  private _model: KettleBaseModel;
  private _visible = false;
  private _isBusy = false;
  private _place: KettleBasePlace = KettleBasePlace.UNKNOWN;
  private _wantPlace: KettleBasePlace = KettleBasePlace.UNKNOWN;

  private _intersectHands = new IntersectionStore();
  private _intersectBase = new IntersectionStore();
  private _visualCheck: IntersectionVisualCheckStore;

  public get model(): KettleBaseModel {
    return this._model;
  }

  public get intersectHands(): IntersectionStore {
    return this._intersectHands;
  }

  public get intersectBase(): IntersectionStore {
    return this._intersectBase;
  }

  public get visualCheck(): IntersectionVisualCheckStore {
    return this._visualCheck;
  }

  public get areAllChecked(): boolean {
    return this._visualCheck.allMarked;
  }

  public get wantPlace(): KettleBasePlace {
    return this._wantPlace;
  }

  public get isOnTable(): boolean {
    return this._place === KettleBasePlace.ON_TABLE;
  }

  public get wantInHand(): HandType | undefined {
    if (this._wantPlace === KettleBasePlace.IN_MAIN_HAND) return HandType.MAIN;
    if (this._wantPlace === KettleBasePlace.IN_MINOR_HAND)
      return HandType.MINOR;
    return undefined;
  }

  public get isInHand(): HandType | undefined {
    if (this._place === KettleBasePlace.IN_MAIN_HAND) return HandType.MAIN;
    if (this._place === KettleBasePlace.IN_MINOR_HAND) return HandType.MINOR;
    return undefined;
  }

  public get isBusy(): boolean {
    return this._isBusy;
  }

  public get isVisible(): boolean {
    return this._visible;
  }

  constructor(model: KettleBaseModel) {
    this._model = model;
    this._visualCheck = new IntersectionVisualCheckStore({
      meshes: this._model.visualCheckBbox,
    });
    makeAutoObservable(this, {
      setVisibility: action,
      setWantPlace: action,
      setIsBusy: action,
      moveToHand: action,
    });
  }

  /**
   * Установить виден ли предмет
   */
  public setVisibility(isVisible: boolean): void {
    this._visible = isVisible;
  }

  /**
   * Установить хочет ли объект быть в руках
   */
  public setWantPlace(wantPlace: KettleBasePlace): void {
    this._wantPlace = wantPlace;
  }

  /**
   * Установить занята ли подставка
   */
  public setIsBusy(isBusy: boolean): void {
    this._isBusy = isBusy;
  }

  /**
   * Переместить подставку в руку
   */
  public moveToHand(parent: TransformNode | null, hand: HandType): void {
    this._place =
      hand === HandType.MAIN
        ? KettleBasePlace.IN_MAIN_HAND
        : KettleBasePlace.IN_MINOR_HAND;
    this._model.root.parent = parent;
    this._model.root.position = new Vector3(0, 0, 0);
    this._model.root.rotation = new Vector3(0, 0, 0);
  }

  /**
   *
   * Переместить подставку на стол
   */
  public moveToTable(parent: TransformNode | null): void {
    this._place = KettleBasePlace.ON_TABLE;
    this._model.root.setParent(parent);
    this._model.root.position.y = 0;
    this._model.root.rotation.x = 0;
    this._model.root.rotation.z = 0;
  }
}
