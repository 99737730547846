import { autorun } from 'mobx';

import { LessonStore } from 'features/lesson-page/store';

import { XRConfig } from './types';
import { XRStore } from './store';

export function setupLogic(
  store: LessonStore,
  objStore: XRStore,
  cfg: XRConfig
): void {
  autorun(() => {
    const floor = store.objects.world?.landscape?.model.collisionFloor;
    floor && objStore.setCollisionFloor(floor);
  });
}
