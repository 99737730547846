import { ModelConfig } from '../../common/types';

export type KettleBaseConfig = {
  model: ModelConfig;
};

export enum KettleBasePlace {
  UNKNOWN,
  IN_MAIN_HAND,
  IN_MINOR_HAND,
  ON_TABLE,
}
