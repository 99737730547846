import { action, makeAutoObservable } from 'mobx';

import { KitchenwareType } from '../../../kitchenware/types';
import { ObjectsType } from '../../../types';

export interface IInventoryItem {
  /**
   * Группа, к которой относится item
   */
  get group(): ObjectsType | undefined;

  /**
   * Пустой ли слот
   */
  get isEmpty(): boolean;

  /**
   * Сконвертировать item в KitchenwareType. Если конвертация невозможна, то вурнуть undefined
   */
  get toKitchenware(): KitchenwareType | undefined;
}

export class InventoryItem {
  private _group?: ObjectsType;
  private _object: unknown = undefined;

  public get group(): ObjectsType | undefined {
    return this._group;
  }

  public get object(): unknown {
    return this._object;
  }

  public get isEmpty(): boolean {
    return this._group === undefined || this._object === undefined;
  }

  public get toKitchenware(): KitchenwareType | undefined {
    if (this._group !== ObjectsType.KITCHENWARE) return undefined;
    return this._object as KitchenwareType;
  }

  constructor() {
    makeAutoObservable(this, {
      set: action,
    });
  }

  public set(
    group: ObjectsType | undefined,
    object: unknown | undefined
  ): void {
    this._group = group;
    this._object = object;
  }
}
