import { TransformNode } from '@babylonjs/core';
import { action, makeAutoObservable } from 'mobx';

import { InfoPanelModel } from './model';

export interface IInfoPanelStore {
  /**
   * Текст, отображаемый на панели
   */
  get content(): string;

  /**
   * Видна ли панель
   */
  get isVisible(): boolean;
}

export class InfoPanelStore implements IInfoPanelStore {
  private _model: InfoPanelModel;

  private _content = '';
  private _isVisible = false;
  private _parent?: TransformNode;

  public get model(): InfoPanelModel {
    return this._model;
  }

  public get content(): string {
    return this._content;
  }

  public get isVisible(): boolean {
    return this._isVisible;
  }

  public get parent(): TransformNode | undefined {
    return this._parent;
  }

  constructor(model: InfoPanelModel) {
    this._model = model;
    makeAutoObservable(this, {
      setContent: action,
      setVisibility: action,
      moveTo: action,
    });
  }

  /**
   * Установить текст на панели
   */
  public setContent(content: string): void {
    this._content = content;
  }

  /**
   * Установить видемость панели
   */
  public setVisibility(isVisible: boolean): void {
    this._isVisible = isVisible;
  }

  /**
   * Установить родителя объекта
   */
  public moveTo(parent: TransformNode): void {
    this._parent = parent;
  }
}
