import { autorun } from 'mobx';

import { AdvancedDynamicTexture } from '@babylonjs/gui';
import { Scene, Texture } from '@babylonjs/core';

import { BaseModelObject } from '../../base';

import { AskIfAcceptWindowConfig } from './types';
import { AskIfAcceptWindowGUI } from './gui';
import { AskIfAcceptWindowModel } from './model';
import { AskIfAcceptWindowStore } from './store';

export class AskIfAcceptWindowObject extends BaseModelObject<
  AskIfAcceptWindowModel,
  AskIfAcceptWindowStore,
  AskIfAcceptWindowConfig
> {
  private _texture: AdvancedDynamicTexture;
  public gui: AskIfAcceptWindowGUI;

  constructor(
    scene: Scene,
    model: AskIfAcceptWindowModel,
    store: AskIfAcceptWindowStore,
    cfg: AskIfAcceptWindowConfig
  ) {
    super(scene, model, store, cfg);
    this._texture = new AdvancedDynamicTexture(
      'AskIfAcceptWindow',
      Math.round(cfg.width * (100 / 2.54) * cfg.dpi),
      Math.round(cfg.height * (100 / 2.54) * cfg.dpi),
      scene,
      false,
      Texture.TRILINEAR_SAMPLINGMODE,
      true
    );
    this.gui = new AskIfAcceptWindowGUI(this._texture, cfg);
    this.gui.scale = cfg.uiScale * (cfg.dpi / 7.5);

    // Настройка материала окна
    this.model.material.emissiveTexture = this._texture;
    this.model.material.opacityTexture = this._texture;
    this._texture.attachToMesh(this.model.mesh, true);
  }

  public static async setup(
    scene: Scene,
    cfg: AskIfAcceptWindowConfig
  ): Promise<AskIfAcceptWindowObject> {
    const model = await AskIfAcceptWindowModel.load(scene, cfg);
    const store = new AskIfAcceptWindowStore(model);
    return new AskIfAcceptWindowObject(scene, model, store, cfg);
  }

  protected _connectToStore(
    store: AskIfAcceptWindowStore,
    cfg: AskIfAcceptWindowConfig
  ): void {
    autorun(() => {
      this.model.setVisibility(store.isOpen);
    });
    autorun(() => {
      if (store.parent) this.model.root.parent = store.parent;
    });
  }
}
