import { makeAutoObservable } from 'mobx';

import {
  AskIfAcceptPanelStore,
  IAskIfAcceptPanelStore,
} from '../ask-if-accept-panel';
import { InfoPanelStore } from '../info-panel';
import { ResultPanelStore } from '../result-panel';

export type SceneStoreList = {
  askIfAcceptPanel1?: AskIfAcceptPanelStore;
  askIfAcceptPanel2?: AskIfAcceptPanelStore;
  infoPanel?: InfoPanelStore;
  resultPanel?: ResultPanelStore;
};

export interface ISceneStore {
  /**
   * Панель с вопросом 1
   */
  get askIfAcceptPanel1(): IAskIfAcceptPanelStore | undefined;

  /**
   * Панель с вопросом 2
   */
  get askIfAcceptPanel2(): IAskIfAcceptPanelStore | undefined;

  /**
   * Панель с информацией
   */
  get infoPanel(): InfoPanelStore | undefined;

  /**
   * Панель с результатами
   */
  get resultPanel(): ResultPanelStore | undefined;
}

export class SceneStore implements ISceneStore {
  private _stores: SceneStoreList;

  get askIfAcceptPanel1(): AskIfAcceptPanelStore | undefined {
    return this._stores.askIfAcceptPanel1;
  }

  get askIfAcceptPanel2(): AskIfAcceptPanelStore | undefined {
    return this._stores.askIfAcceptPanel2;
  }

  public get infoPanel(): InfoPanelStore | undefined {
    return this._stores.infoPanel;
  }

  public get resultPanel(): ResultPanelStore | undefined {
    return this._stores.resultPanel;
  }

  constructor(stores: SceneStoreList) {
    this._stores = stores;

    makeAutoObservable(this);
  }
}
