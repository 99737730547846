import { Layout } from 'antd';
import { LessonType } from 'api';
import { observer } from 'mobx-react';
import { useRootData } from 'hooks';
import React, { FC } from 'react';

import { SceneLoadingProgressType } from 'features/lesson-page/types';

import { FinishButton, InitialModal, Scene, UserHints } from '../components';

import css from './index.module.css';

const AvailableLesson: FC = observer(() => {
  const { lessonType, isLessonStarted, sceneLoadingProgress } = useRootData(
    (store) => ({
      lessonType: store.lesson.lessonType,
      isLessonStarted: !!store.lesson.lessonStartTime,
      isLessonFinished: !!store.lesson.lessonFinishTime,
      isExamResultsPanelVisible: !!store.lesson.isExamResultsPanelVisible,
      sceneLoadingProgress: store.lesson.sceneLoadingScreenProgress,
      unfulfilledRequiredStates: store.lesson.unfulfilledRequiredStates,
    })
  );

  return (
    <Layout className={css.layout}>
      <InitialModal />
      {isLessonStarted && (
        <>
          <Scene />
          {sceneLoadingProgress === SceneLoadingProgressType.Loaded && (
            <>
              {lessonType !== LessonType.Exam && <UserHints />}
              <FinishButton />
            </>
          )}
        </>
      )}
    </Layout>
  );
});

export default AvailableLesson;
