import { action, makeAutoObservable } from 'mobx';

import { ILandscapeModel, LandscapeModel } from './model';

export interface ILandscapeStore {
  /**
   * Модель ланшафта
   */
  get model(): ILandscapeModel;

  /**
   * Виден ли объект
   */
  get isVisible(): boolean;
}

export class LandscapeStore implements ILandscapeStore {
  private _isVisible = true;
  private _isCollisionEnabled = true;
  private _model: LandscapeModel;

  public get model(): LandscapeModel {
    return this._model;
  }

  public get isVisible(): boolean {
    return this._isVisible;
  }

  public get isCollisionEnabled(): boolean {
    return this._isCollisionEnabled;
  }

  constructor(model: LandscapeModel) {
    this._model = model;
    makeAutoObservable(this, {
      setVisibility: action,
      setCollisionEnabled: action,
    });
  }

  public setVisibility(isVisible: boolean): void {
    this._isVisible = isVisible;
  }

  public setCollisionEnabled(isEnabled: boolean): void {
    this._isCollisionEnabled = isEnabled;
  }
}
