import { autorun, reaction } from 'mobx';

import { LessonStore } from 'features/lesson-page/store';

import { IntersectionTarget } from '../../common/intersection';

import { HandType } from '../../player/hands';
import { KettleFilterConfig, KettleFilterPlace } from './types';
import { KettleFilterStore } from './store';
import { KitchenwareType } from '../types';
import { ObjectsType } from '../../types';

enum IntersectionTargetId {
  FILTER_TAKE_BBOX,
  KETTLE_FILTER_BBOX,
  HAND_MAIN,
  HAND_MINOR,
}

export function setupLogic(
  store: LessonStore,
  objStore: KettleFilterStore,
  cfg: KettleFilterConfig
): void {
  const stCore = store.objects.core;
  const stPlayer = store.objects.player;
  const stKitchenware = store.objects.kitchenware;
  if (!stCore) throw Error('Cannot configure without objects.core');
  if (!stPlayer) throw Error('Cannot configure without objects.player');
  if (!stKitchenware)
    throw Error('Cannot configure without objects.kitchenware');

  const { xr } = stCore;
  const { hands, inventory } = stPlayer;
  const { kettle } = stKitchenware;
  if (!xr) throw Error('Cannot configure without objects.core.xr');
  if (!hands) throw Error('Cannot configure without objects.player.hands');
  if (!inventory)
    throw Error('Cannot configure without objects.player.inventory');
  if (!kettle)
    throw Error('Cannot configure without objects.kitchenware.kettle');

  objStore.setVisibility(true);
  objStore.setWantPlace(KettleFilterPlace.IN_KETTLE);

  inventory.registerObjectWantInHand(objStore, {
    group: ObjectsType.KITCHENWARE,
    object: KitchenwareType.KETTLE_FILTER,
  });

  // Установка целевых объектов
  autorun(() => {
    objStore.intersect.setTargets1([
      {
        id: IntersectionTargetId.FILTER_TAKE_BBOX,
        mesh: objStore.model.takeBbox,
      },
    ]);

    const targets2: IntersectionTarget[] = [];
    if (objStore.isInHand !== undefined) {
      targets2.push({
        id: IntersectionTargetId.KETTLE_FILTER_BBOX,
        mesh: kettle.model.filterBbox,
      });
    }
    if (
      objStore.canBeTakenInHand &&
      inventory.mainHandItem.isEmpty &&
      hands.mainHand.isVisible
    )
      targets2.push({
        id: IntersectionTargetId.HAND_MAIN,
        mesh: hands.mainHand.model.bbox,
      });
    if (
      objStore.canBeTakenInHand &&
      inventory.minorHandItem.isEmpty &&
      hands.minorHand.isVisible
    )
      targets2.push({
        id: IntersectionTargetId.HAND_MINOR,
        mesh: hands.minorHand.model.bbox,
      });

    objStore.intersect.setTargets2(targets2);
  });

  autorun(() => {
    objStore.intersect.setEnabled(objStore.isVisible);
  });

  // Изменение положения объекта в зависимости от его желания, состояния интерфейса и рук
  let handAnimationIsDone = false;
  autorun(() => {
    switch (objStore.wantPlace) {
      case KettleFilterPlace.IN_KETTLE:
        objStore.moveToKettle(kettle.model.filterHelper);
        break;
      case KettleFilterPlace.IN_MAIN_HAND:
      case KettleFilterPlace.IN_MINOR_HAND: {
        const isInHand = inventory.find(
          ObjectsType.KITCHENWARE,
          KitchenwareType.KETTLE_FILTER
        );
        if (isInHand === undefined) break;

        const hand = hands.getHand(isInHand);
        if (hand.animating) {
          handAnimationIsDone =
            handAnimationIsDone || objStore.isInHand !== isInHand;
          break;
        }

        if (handAnimationIsDone) {
          handAnimationIsDone = false;
          objStore.moveToHand(hand.model.helperTake, hand.type);
        }
      }
    }
  });

  // Установка в какой момент фильтр может быть взят в руку
  autorun(() => {
    objStore.setCanBeTakenInHand(kettle.isCapOpen);
  });

  const onStandardTriggerDown = (isLeft: boolean): void => {
    const hand = isLeft ? hands.left : hands.right;
    const handTargetId =
      hand.type === HandType.MAIN
        ? IntersectionTargetId.HAND_MAIN
        : IntersectionTargetId.HAND_MINOR;

    // Проверка есть ли пересечение руки, на кнопку которой нажали, с фильтром
    if (
      objStore.intersect.isEnabled &&
      objStore.intersect.has(
        IntersectionTargetId.FILTER_TAKE_BBOX,
        handTargetId
      )
    ) {
      objStore.setWantPlace(
        hand.isMainHand
          ? KettleFilterPlace.IN_MAIN_HAND
          : KettleFilterPlace.IN_MINOR_HAND
      );
      return;
    }

    // Проверка есть ли пересечение фильтра в руке, на которую нажали с чайником
    if (
      hand.type === objStore.isInHand &&
      objStore.intersect.isEnabled &&
      objStore.intersect.has(
        IntersectionTargetId.FILTER_TAKE_BBOX,
        IntersectionTargetId.KETTLE_FILTER_BBOX
      )
    ) {
      objStore.setWantPlace(KettleFilterPlace.IN_KETTLE);
    }
  };

  reaction(
    () => xr.controllers.left?.buttons.standardTriggerDown,
    (isDown) => isDown && onStandardTriggerDown(true)
  );
  reaction(
    () => xr.controllers.right?.buttons.standardTriggerDown,
    (isDown) => isDown && onStandardTriggerDown(false)
  );
}
