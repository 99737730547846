import { Scene } from '@babylonjs/core';

import { BaseController, BaseObject } from '../common/base';

import { CoreObject } from '../core';
import { FurnitureObject } from '../furniture';
import { KitchenwareObject } from '../kitchenware';
import { PlayerObject } from '../player';
import { SceneObject } from '../scene';
import { WorldObject } from '../world';

import { ObjectsConfig } from './types';
import { ObjectsStore, ObjectsStoreList } from './store';

import { LessonScene } from '../../init';
import { ObjectsType } from '../types';

type ObjectClassesList = {
  [key in ObjectsType]: {
    setup: (
      scene: Scene,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cfg: any
    ) => Promise<BaseController<unknown, unknown>>;
  };
};

const objectClasses: ObjectClassesList = {
  core: CoreObject,
  furniture: FurnitureObject,
  kitchenware: KitchenwareObject,
  player: PlayerObject,
  scene: SceneObject,
  world: WorldObject,
};

type ObjectsObjectList = {
  [key in ObjectsType]: BaseController<unknown, unknown> | undefined;
};

type ObjectsObjectRawList = {
  core?: CoreObject;
  kitchenware?: KitchenwareObject;
  player?: PlayerObject;
  scene?: SceneObject;
  world?: WorldObject;
};

export class ObjectsObject extends BaseObject<ObjectsStore, ObjectsConfig> {
  private _raw: ObjectsObjectRawList;

  constructor(
    scene: Scene,
    objects: ObjectsObjectList,
    store: ObjectsStore,
    cfg: ObjectsConfig
  ) {
    super(scene, store, cfg);
    this._raw = objects as ObjectsObjectRawList;
    for (const obj of Object.values(objects)) {
      obj && this.registerObject(obj);
    }
  }

  // TODO: Только для поддержки legacy кода
  public get raw(): ObjectsObjectRawList {
    return this._raw;
  }

  public static async setup(
    scene: Scene,
    cfg: ObjectsConfig
  ): Promise<ObjectsObject> {
    const objectKeys = Object.values(ObjectsType);

    const objectsToLoad = [];
    for (const k of objectKeys) {
      objectsToLoad.push(
        cfg[k]
          ? objectClasses[k].setup(scene, cfg[k])
          : Promise.resolve(undefined)
      );
    }
    const loadedObjects = await Promise.all(objectsToLoad);

    // Build objects and stores
    const objects: ObjectsObjectList = {} as ObjectsObjectList;
    const stores: ObjectsStoreList = {} as ObjectsStoreList;

    for (let i = 0; i < objectKeys.length; i += 1) {
      const k = objectKeys[i];
      objects[k] = loadedObjects[i];
      stores[k] = loadedObjects[i]?.store;
    }

    return new ObjectsObject(scene, objects, new ObjectsStore(stores), cfg);
  }

  public setupLegacyLogic(scene: LessonScene): void {
    this.raw.scene?.setupLegacyLogic(scene);
  }
}
