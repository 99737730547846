import { ModelConfig } from '../../common/types';

export type SingleHandConfig = {
  model: ModelConfig;
};

export type HandsConfig = {
  left: SingleHandConfig;
  right: SingleHandConfig;
};

export enum HandType {
  MAIN,
  MINOR,
}

export enum HandState {
  BRUSH,
  CROCODILE,
  FIXED,
  IPP,
  KETTLE_TAKE,
  KETTLE_FILTER_TAKE,
  KEY,
  STAND_TAKE,
  TABLET_HORIZONTAL,
  TAKE,
  TOUCHING,
  TUBE,
  WRENCH,
}
