import { autorun } from 'mobx';

import { LessonStore } from 'features/lesson-page/store';

import { HandType } from '../../player/hands';
import { IntersectionVisualCheckConfig } from './types';
import { IntersectionVisualCheckStore } from './store';

import { IntersectionTarget } from '../intersection';

export function setupLogic(
  store: LessonStore,
  objStore: IntersectionVisualCheckStore,
  cfg: IntersectionVisualCheckConfig
): void {
  const stPlayer = store.objects.player;
  if (!stPlayer) throw Error('Cannot configure without objects.player');

  const { hands, inventory } = stPlayer;
  if (!hands) throw Error('Cannot configure without objects.player.hands');

  if (cfg.empty_hands_only && !inventory)
    throw Error('Cannot configure without objects.player.inventory');

  // Установка целевых объектов
  autorun(() => {
    const targets1: IntersectionTarget[] = [];
    if (!cfg.empty_hands_only || inventory?.mainHandItem.isEmpty)
      targets1.push({
        id: HandType.MAIN,
        mesh: hands.mainHand.model.bbox,
      });

    if (!cfg.empty_hands_only || inventory?.minorHandItem.isEmpty)
      targets1.push({
        id: HandType.MINOR,
        mesh: hands.minorHand.model.bbox,
      });

    objStore.intersect.setTargets1(targets1);
  });
}
