import { autorun, reaction } from 'mobx';

import { LessonStore } from 'features/lesson-page/store';

import { IntersectionTarget } from '../../common/intersection';

import { HandType } from '../../player/hands';
import { KettleBaseConfig, KettleBasePlace } from './types';
import { KettleBaseStore } from './store';
import { KitchenwareType } from '../types';
import { ObjectsType } from '../../types';

enum KettleBaseIntersectionTarget {
  KETTLE_BASE_BODY,
}

enum KitchenIntersectionTarget {
  KITCHEN_TABLE_RIGHT,
}

export function setupLogic(
  store: LessonStore,
  objStore: KettleBaseStore,
  cfg: KettleBaseConfig
): void {
  const stCore = store.objects.core;
  const stPlayer = store.objects.player;
  const stKitchenware = store.objects.kitchenware;
  const stKitchFurniture = store.objects.furniture;
  if (!stCore) throw Error('Cannot configure without objects.core');
  if (!stPlayer) throw Error('Cannot configure without objects.player');
  if (!stKitchenware)
    throw Error('Cannot configure without objects.kitchenware');
  if (!stKitchFurniture)
    throw Error('Cannot configure without objects.furniture');

  const { xr } = stCore;
  const { hands, inventory } = stPlayer;
  const { kitchenAll } = stKitchFurniture;
  if (!xr) throw Error('Cannot configure without objects.core.xr');
  if (!hands) throw Error('Cannot configure without objects.player.hands');
  if (!inventory)
    throw Error('Cannot configure without objects.player.inventory');
  if (!kitchenAll)
    throw Error('Cannot configure without objects.furniture.kitchenAll');
  objStore.setVisibility(true);

  inventory.registerObjectWantInHand(objStore, {
    group: ObjectsType.KITCHENWARE,
    object: KitchenwareType.KETTLE_BASE,
  });

  // Установка целевых объектов
  autorun(() => {
    const targets1: IntersectionTarget[] = [];
    if (inventory.mainHandItem.isEmpty && hands.mainHand.isVisible)
      targets1.push({ id: HandType.MAIN, mesh: hands.mainHand.model.bbox });
    if (inventory.minorHandItem.isEmpty && hands.minorHand.isVisible)
      targets1.push({ id: HandType.MINOR, mesh: hands.minorHand.model.bbox });
    objStore.intersectHands.setTargets1(targets1);

    const targets2: IntersectionTarget[] = [];
    if (!objStore.isBusy)
      targets2.push({
        id: KettleBaseIntersectionTarget.KETTLE_BASE_BODY,
        mesh: objStore.model.takeBbox,
      });
    objStore.intersectHands.setTargets2(targets2);
  });

  // Установка целевых объектов для intersectBase
  autorun(() => {
    objStore.intersectBase.setTargets1([
      {
        id: KettleBaseIntersectionTarget.KETTLE_BASE_BODY,
        mesh: objStore.model.kettleBbox,
      },
    ]);
    objStore.intersectBase.setTargets2([
      {
        id: KitchenIntersectionTarget.KITCHEN_TABLE_RIGHT,
        mesh: kitchenAll.model.rightCountertopBbox,
      },
    ]);
  });

  autorun(() => {
    objStore.intersectHands.setEnabled(objStore.isVisible);
    objStore.intersectBase.setEnabled(objStore.isVisible);
  });

  // Изменение положения объекта в зависимости от его желания, состояния интерфейса и рук
  let handAnimationIsDone = false;
  autorun(() => {
    switch (objStore.wantPlace) {
      case KettleBasePlace.ON_TABLE:
        objStore.moveToTable(kitchenAll.model.rightCountertopBbox);
        break;
      case KettleBasePlace.IN_MAIN_HAND:
      case KettleBasePlace.IN_MINOR_HAND: {
        const isInHand = inventory.find(
          ObjectsType.KITCHENWARE,
          KitchenwareType.KETTLE_BASE
        );
        if (isInHand === undefined) break;

        const hand = hands.getHand(isInHand);
        if (hand.animating) {
          handAnimationIsDone =
            handAnimationIsDone || objStore.isInHand !== isInHand;
          break;
        }

        if (handAnimationIsDone) {
          handAnimationIsDone = false;
          objStore.moveToHand(hand.model.helperTake, hand.type);
        }
      }
    }
  });

  autorun(() => {
    objStore.visualCheck.setEnabled(
      store.script?.currentStep?.doneCondition?.stateName ===
        'isKettleBaseLabelsTouched'
    );
  });

  // Установка параметра isBusy
  autorun(() => {
    let isBusy = objStore.isInHand !== undefined;
    if (!isBusy && stKitchenware?.kettle)
      isBusy = stKitchenware.kettle.isOnBase;
    objStore.setIsBusy(isBusy);
  });

  const onStandardTriggerDown = (isLeft: boolean): void => {
    const hand = isLeft ? hands.left : hands.right;
    const isKettleBaseInHand = objStore.isInHand;
    // Проверка есть ли пересечение руки, на кнопку которой нажали, с подставкой
    if (
      objStore.intersectHands.isEnabled &&
      objStore.intersectHands.has(
        hand.type,
        KettleBaseIntersectionTarget.KETTLE_BASE_BODY
      )
    ) {
      objStore.setWantPlace(
        hand.isMainHand
          ? KettleBasePlace.IN_MAIN_HAND
          : KettleBasePlace.IN_MINOR_HAND
      );
    }
    // Проверка есть ли пересечение подставки co столом
    if (
      objStore.intersectBase.isEnabled &&
      isKettleBaseInHand === hand.type &&
      objStore.intersectBase.has(
        KettleBaseIntersectionTarget.KETTLE_BASE_BODY,
        KitchenIntersectionTarget.KITCHEN_TABLE_RIGHT
      )
    ) {
      objStore.setWantPlace(KettleBasePlace.ON_TABLE);
    }
  };

  reaction(
    () => xr.controllers.left?.buttons.standardTriggerDown,
    (isDown) => isDown && onStandardTriggerDown(true)
  );
  reaction(
    () => xr.controllers.right?.buttons.standardTriggerDown,
    (isDown) => isDown && onStandardTriggerDown(false)
  );
}
